
.v2filterRowContainer{
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.v2filterItemContainer{
    height: 60px;
    flex-wrap: wrap;
    margin: 2px;
    text-align: center;
    background-color: #EDEDED; /* Just for demonstration, you can customize this */
    color: black;
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    /* flex-grow: 1; */
    box-sizing: border-box;
    display: flex;
    align-items: center;
    transition: 0.2s all ease-in-out;
}
.v2filterItemContainer:nth-child(1), .v2filterItemContainer:nth-child(6){
    /* border-radius: 5px 0px 0px 5px; */
}
.v2filterItemContainer:nth-child(5), .v2filterItemContainer:nth-child(10){
    /* border-radius: 0px 5px 5px 0px; */
}
.v2FilterIcon{
    width: 24px;
    height: 24px;
    transform: scale(0.8);
    flex-shrink: 0;
    position: absolute;
}
.v2FilterTextField{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    outline: none;
    color: #252525;
    font-family: Kanit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 35px;
    padding-top: 0px;
    padding-bottom: 0px;
}
.v2FilterTextField::placeholder{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}

.v2CheckboxIcon{
    margin-left: -0px;
    height: 22px;
    width: 22px;
    cursor: pointer;
}
.v2CheckboxIcon:after{
    content: " ";
    background-color: #9FFF9D;
    display: inline-block;
    visibility: visible;
}
.v2CheckboxIcon:checked:after{
    height: 22px;
    width: 22px;
    color: white;
    border-radius: 2px;
    outline: 0.01em solid rgb(100,100,100);
    font-size: 19px;
    text-align: center;
    background-color: var(--ambient-color) !important;
    content: "\2714";
}

.v2CheckboxLabel{
    padding-left: 35px;
    color: #252525;
    font-family: Kanit;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: default;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input{
    font-size: 12px !important;
}

.v2SelectComponentActive{
    z-index: 20;
    transform: scale(1.01);
    border-radius: 10px 10px 0px 0px  !important;
    box-shadow: 0px 0px 5px 2px rgb(200,200,200);
}


.v2FilterButtonContainer{
    height: 58px;
    flex: 0 0 calc(20% - 20px); /* Adjust the width based on your design */
    margin: 2px;
    margin-top: 3px;
    text-align: center;
    color: black;
    flex-grow: 1;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    transition: 0.2s all ease-in-out;
}
.v2FilterButtonItem{
    height: 58px;
    flex: 0 0 calc(50% - 4px); /* Adjust the width based on your design */
    margin: 2px;
    text-align: center;
    flex-grow: 1;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s all ease-in-out;
    font-family: Kanit;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: default;
    outline: 1px solid var(--container-shadow-color);
}
.v2FilterButtonItem:first-child{
    color: var(--container-shadow-color);
}
.v2FilterButtonItem:last-child{
    background-color: var(--container-shadow-color);
    color: white;
    /* border-radius: 0 10px 10px 0px; */
}

.react-datepicker-wrapper {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
.react-datepicker__input-container{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
