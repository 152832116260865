:root{
    --container-bg-color: rgb(255, 255, 255);
    --container-shadow-color: rgb(159, 80, 0);
    --container-shadow-color-hover: rgb(228, 106, 0);
    --container-shadow-color-hover-lighter:rgb(255, 168, 68);
    --container-shadow-color-hover-more-lighter:rgb(251, 216, 177);
    --tab-font-color: rgb(117, 117, 117);
    --tab-font-color-hover: rgb(80, 80, 80);
    --table-headers-background: rgb(230, 230, 230);
    --font-color:white;
}

.tableModalSettingsContainer{
    min-height: 100vh;
    position: fixed;
    width: 100%;
    background-color: transparent;
    backdrop-filter: blur(1px);
    z-index: 10000000;
    transform: scale(1);
    transition: 0.5s all ease-in-out;
    display: flex;
    top:0;
    left: 0;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.tableModalSettingsContainerHide{
    transform: scale(0.0) translateY(-200%);
    transition: 0.5s all ease-in-out;
}
.tableModalSettingsWrapper{
    display: flex;
    align-items: center;
    width: 70%;
    height:70vh;
    transition: 0.7s all ease-in-out;
    border-radius: 15px;
    background-color: white;
    box-shadow: 0 0 5px 1px grey;
}
.tableModalSettingsDragDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:200px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
}
.tableModalHead{
    padding-top: 10px;
    padding-bottom: 10px;
    background: rgb(247, 247, 247);
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 600;
    border:0px;
    text-align: center;
    position: relative;
    transition: all 0.5s linear;
    /* border-right: 1px solid rgb(200,200,200); */
}
.tableModalHead:last-child{
    /* border-right: 0px solid rgb(200,200,200); */
}
.tableModalSortingDiv{
    background-color: var(--container-shadow-color-hover-more-lighter);
    height: 15px;
    width: 15px;
    margin-top: 2px;
    font-size: 10px;
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    right:0;
}
.hidetableModalSortingDiv{
    display: none;
}
.overflowText{
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
}
.tableModalValue{
    transition: all 0.5s linear;
    height: 25px;
    font-size: 13px;
    /* border-right: 1px solid rgb(200,200,200); */
    border-bottom: 1px solid rgb(241, 241, 241);
    /* display: block; */
    background-color: white;
}
.tableModalRow{
    background-color: white;
}
.tableModalRow:hover{
    background-color: var(--container-shadow-color-hover-more-lighter);
}
.tableModalValue:last-child{
    /* border-right: 1px solid rgb(255, 255, 255); */
}
.mouseOverText{
    margin-top: -16px;
    margin-left: -16px;
    max-width: 400px;
    white-space:unset;
    background-color: white;
    overflow-wrap: break-word;
    min-height: 20px;
    z-index: 100;
    height: max-content;
    word-wrap: break-word;
    word-break:break-all;
    position: absolute;
    box-shadow: 0 0 5px 1px grey;
}

.mobileViewContentContainer{
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
    justify-content: center;
}

.mobileViewContentWrapper{
    min-width: 300px;
    min-height: 200px;
}

.tableModalThreeDotMenu{
    z-index: 991;
    min-height: 150px;
    min-width: 150px;
    box-shadow: 0 0 4px 1px grey;
    background-color: white;
    margin-left: -150px;
    position: absolute;
}