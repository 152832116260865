.container{
    display: flex;
    height: auto;
    padding-top: 2%;
    padding-left: 5%;
}

.leftSideColumn{
    text-align: end;
    font-size: 14px;
    padding-top: 1%;
    width: 10%;
    /* background-color: violet; */
}

.rightSideColumn{
    padding-left: 5%;
    padding-top: 1%;
    text-align:left;
    font-size: 14px;
}