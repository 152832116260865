.advertiserBillingContainer{
    background-color: white;
    margin-top: -10px;
    /* margin-left:40px; */
    /* margin-right:40px; */
    height: calc(100vh - 70px);
    /* padding-top: 20px; */
    /* padding-bottom: 0px; */
    position: relative;
    overflow: auto;
}

.advertiserLabel{
    font-size: 24px;
    font-weight: 600;
    margin-left: 20px;
}
.advertiserFilters{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
}
.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text{
    padding: 5px;
}
.react-datepicker__year .react-datepicker__year-text{
    padding: 5px;
}
.monthPicker{
    outline: none;
    border: 1px solid transparent;
    padding: 10px;
    padding-left: 0px;
    font-family:Arial, Helvetica, sans-serif;
    color: black;
    font-weight: 500;
    border-bottom: 1px solid rgb(200,200,200);
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input{
    padding: 10px 0 5px !important;
}
.filterItemLabel{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
}
.filterItem{
    margin-right: 30px;
    margin-bottom: 30px;
}
.paymentUpdateWrapper{
    margin-left: 20px;
    margin-right: 20px;
}
.labelValueFlex{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
.paymentItemLabel{
    font-weight: 500;
}
.paymentItemValue{

}

.approvedRevenueTextFieldContainer{
    width: calc(100%+5px);
    height: 30px;
    margin-bottom: -10px;
    margin-top: -10px;
    margin-left: -5px;
    margin-right: -5px;
    margin-left: -2%;
    outline: none;
    position: relative;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
}
.approvedRevenueTextField{
    width: calc(100%);
    height: 35px;
    margin-left: -5px;
    padding-left: 5px;
    outline: none;
    border: 1px solid transparent;
    -webkit-user-select: auto;
}

.totalApprovedRevenueTextField{
    width: calc(100%);
    height: 35px;
    /* margin-left: -5px; */
    padding-left: 5px;
    outline: none;
    border: 1px solid transparent;
    -webkit-user-select: auto;
}
::-webkit-scrollbar{
    width: 1px !important; 
    height: 1px !important; 
}

.valueSelectedChangesWrapper{
    height: 50px;
    background-color: var(--container-shadow-color-hover);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    z-index: 11;
}
.billingTags{
    padding-inline-start: 20px;
    margin-block-end: 0px;
    margin-block-start: 0px;
}