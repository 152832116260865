
.profilePageContainer{
    min-height: calc(100vh - 60px);
    display: flex;
    margin-top: -10px;
    background-color: rgb(248, 248, 248);
}
.profilePageSidebar{
    width: 23%;
    background-color: white;
    border-right: 1px solid rgb(220,220,220);
}
.profilePageSidebarOptions{
    padding: 20px;
    display: flex;
    align-items: center;
}

.profilePageContainer234{
    width: calc(70% - 40px);
    height: calc(100vh - 90px);
    overflow: auto;
    margin-right: 8%;
    margin-left: 8%;
    margin-top: 30px;
}
.profilePageSubheading{
    font-size: 17px;
    font-weight: 500;
}
.profilePageSubsubheading{
    font-size: 13px;
    margin-top: 5px;
    color:rgb(100,100,100);
}
.profilePageFormItem{
    margin: 10px;
    width: 40%;
}
.profilePageFormLabel{
    font-size: 13px;
    color: rgb(50,50,50);
    font-weight: 500;
}


.profilePageThreeDotButton{
    display: none;
}
.profilePageNavigationTabs{
    cursor: pointer;
}

.addPaymentSystemPopup{
    position: fixed;
    top: 20vh;
    left: 20vw;
    cursor: default;
    min-height: 65vh;
    width: 60vw;
    transform-origin: top;
    background-color: white;
    font-size: 18px;
    color: black;
    box-shadow: 0 10px 10px 1px rgba(128, 128, 128, 0.231);
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 900px) {
        
    .profilePageThreeDotButton{
        display: block;
        position: absolute;
        padding: 10px;
        padding-right: 15px;
        margin: 10px;
        border-radius: 20%;
        color:white;
        height: 20px;
        width: 20px;
        cursor: pointer;
        transform: scale(0.8);
        background-color: var(--container-shadow-color-hover-lighter);
    }

    .profilePageSidebar{
        position: absolute;
        width: 70%;
        height: calc(100vh - 60px);
        margin-left: -70%;
        z-index: 1;
        transition: all 0.5s ease-in-out;
    }
    .profilePageContainer234{
        width: 100%;
    }
    .profilePageFormItem{
        margin: 10px;
        width: 100%;
    }
}