:root{
    --ambient-color: rgb(0, 166, 178);
    --ambient-color-10-percent-lighter: #1aafba;
    --ambient-color-20-percent-lighter: #33b8c1;
    --ambient-color-30-percent-lighter: #4dc1c9;
    --ambient-color-40-percent-lighter: #66cad1;
    --ambient-color-50-percent-lighter: #80d3d9;
    --ambient-color-60-percent-lighter: #99dbe0;
    --ambient-color-70-percent-lighter: #b3e4e8;
    --ambient-color-80-percent-lighter: #ccedf0;
    --ambient-color-90-percent-lighter: #e6f6f7;
    --ambient-color-95-percent-lighter: #f2fbfb;
}

.tablemodelContainer{
    width: 100%;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
}

.lockedHeader{
    
}
.hoverValueRow:hover{
    background-color: var(--container-shadow-color-hover-more-lighter);
}
.sortingIcon:hover{
    color: rgb(100,100,100);
}

.tableHeaderStyling{
    /* border-right:1px solid rgb(150,150,150);
    border-bottom:1px solid rgb(150,150,150); */
    background:var(--ambient-color-70-percent-lighter);
    font-size:1.1rem;
    padding:5px;
    /* overflow:hidden; */
    text-overflow:ellipsis;
    white-space:nowrap;
    transition:all 0.3s linear;
}
.tableHeaderDiv{
    position: relative;
    min-height: 50px;
    display: flex;
    align-items: center;
}

.tableHeaderLabel{
    width: 100%;
    color: #252525;
    font-family: Kanit;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    white-space: break-spaces; 
    line-height: 110%; /* 17.6px */
    font-family: "kanit", sans-serif;
    /* text-transform: uppercase; */
}

.tableValueStyling{
    /* border-right: 1px solid rgb(200,200,200); */
    /* border-bottom: 1px solid rgb(220,220,220); */
    /* background-color: rgb(240,240,240); */
    font-size: 0.8rem;
    padding: 5px;
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
    overflow: hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    min-height: 30px;
    color:black
}
.tableTotalValueStyling{
    /* background-color: rgb(240,240,240); */
    font-size: 0.8rem;
    font-weight: 800;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    background-color: var(--ambient-color-10-percent-lighter);
}
.filterIcon{
    border-radius: 20%;
    position: absolute;
    right: 0px;
    padding: 2px;
}
.filterIcon:hover{
    background-color: var(--ambient-color);
    color: white;
}
.tableModelFilterContainer{
    position: absolute;
    right: 0;
    min-height: 300px;
    width: 200px;
    z-index: 1000;
    background-color: white;
    box-shadow: 0px 3px 10px 0px rgb(220, 220, 220);
}