:root{
    --container-shadow-color-hover: rgb(228, 106, 0);
    --container-shadow-color-hover-lighter:rgb(255, 168, 68);
}
.rowDashboard{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
}

.column{
    display: grid;
}

.columnDataDashboard{
    display: grid;
    background-color: rgb(252, 252, 252);
    margin-top: 1%;
    width: 100%;
    border-radius: 2px;
    box-shadow: 0 0 10px 2px rgb(180, 180, 180);
    margin-bottom: 1%;
    overflow-y: auto;
    transition: all 0.5s ease-in;

}

.columnData:hover{
    box-shadow: 0 0 10px 2px rgb(172, 147, 115);
}

.clicksContainer{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center; 
    overflow-x: hidden;
}
.clickLeftWrapper{
    display: flex;
    height: 100%;
    width: 40%;
    margin-left: 5%;
    /* justify-content: center; */
    align-items: center;
    /* text-align: center; */
}
.clickRightWrapper{
    /* display: flex; */
    height: 100%;
    width: 50%;
    margin-right: 5%;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 0 !important;
}
.paramIncrease{
    display: flex;
    margin-top: 10%;
    font-size: 10px;
    height: 18px;
    width: 60px;
    margin-left: 2%;
    border: 0.2px solid rgb(0, 211, 0);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: palegreen;
    color: rgb(0, 73, 0);
    font-weight: 600;
    cursor: default;
}

.paramDecrease{
    display: flex;
    margin-top: 10%;
    font-size: 10px;
    height: 18px;
    width: 70px;
    margin-left: 2%;
    border: 0.2px solid rgb(240, 54, 54);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgb(255, 77, 77);
    color: rgb(63, 0, 0);
    font-weight: 600;
    cursor: default;

}

.charts{
    z-index: 0 !important;
    position: relative;
}
.affiliateIdTextField{
    width: 99%;
}

.graphs{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 99%;
    z-index: 0 !important;
}

.clickConversionDateOfferContainter{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background-color: black; */
}
.clickConversionDateOfferGraph{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 0 !important;
    overflow-x: auto;
}

.affiliateInfoContainer{
    width: 100%;
}

.leftColumnHead{
    text-align:left;
    padding-top:5%;
    font-size:14px;
    width:50%;
}

.rightColumnHead{
    text-align:right;
    padding-top:5%;
    font-size:14px;
    width:50%;
}

.leftColumnData{
    text-align:left;
    font-size:14px;
    padding-top: 5%;
    width:50%;
    font-size: 11px;
}

.rightColumnData{
    text-align:right;
    font-size:14px;
    width:50%;
    padding-top: 5%;
    font-size: 12px;
}

.columnHead{
    font-size:14px;
    background-color: rgb(236, 236, 236);
    padding:8px;
}
.zoomedBackground{
    height: 100vh;
    width: 100vw;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.342);
    position: fixed;
    z-index: 9999;
    backdrop-filter: blur(1px);
}
.zoomed{
    transition: all 0.2s ease-in;
    position: fixed;
    height: 90vh;
    width: 95vw;
    /* transform: translateX(-33vw) translateY(-48.5vh); */
    /* margin-left: 5%; */
    /* margin-right: 5%; */
    background: white;
    z-index: 10000;
}

.zoomedOut{
    height: 1px;
    width: 1px;
    position: fixed;
    visibility: hidden;
    transform: scale(1px,1px) translateX(-45vw) translateY(0);
    transition: all 0.1s ease-in;
}

.requestedTabActive{
    color:orange;
    font-weight: 700;
    padding: 2%;
    padding-top: 0%;
    cursor: pointer;
}

.requestedTabInactive{
    padding: 2%;
    padding-top: 0%;
    cursor: pointer;
}
.statsData{
    width:30%;
}

@media screen and (max-width: 600px) {
    .rowDashboard{
        flex-wrap: wrap;
    }
    .rowDashboard:nth-child(3){
        flex-wrap: wrap-reverse;
    }
}