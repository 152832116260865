:root{
    --sidebar-bg-color: rgb(255, 119, 0);
    --font-color: rgb(255, 255, 255);
    --hamburger-icon-hover-color:rgb(186, 87, 0);
    --menu-item-hover-color:rgb(255, 255, 255);
    --menu-item-hover-font-color:black;
}

.sidebarContainerV2Open{
    color:var(--font-color);
    position: fixed;
    height: calc(100vh);
    top:0;
    left: 0;
    background-color: var(--sidebar-bg-color);
    transition: all 0.7s ease-in-out;
    display: flex;
    justify-content: center;
    padding-top: 5px;
    z-index: 10000;
    overflow-y: auto;
    /* margin-top: 50px; */
}

.sidebarContainerV2Close{
    color:var(--font-color);
    position: fixed;
    height: calc(100vh);
    top:0;
    left: 0;
    background-color: var(--sidebar-bg-color);
    transition: all 0.7s ease-in-out;
    display: flex;
    justify-content: center;
    padding-top: 5px;
    z-index: 100000;
    overflow-y: auto;
    overflow-x: hidden;
    /* margin-top: 50px; */
}
.block{
    display: block;
    width: 100%;
}
.openCloseSidebar{
    display: flex;
    justify-content: flex-start;
    align-items: center;   
}

.hamburgerIcon{
    border-radius: 50%;
    transition: all 0.7s ease-in-out;
}
.hamburgerIcon:hover{
    background-color: var(--hamburger-icon-hover-color);
}

.companyName{
    font-family:Arial, Helvetica, sans-serif;
    user-select: none;
    font-size: 15px;
    font-weight: 800;
    width: max-content;
    letter-spacing: 0.0009rem;
    padding-right: 5%;
    transition: all 0.7s ease-in-out;
}

.companyNameHidden{
    user-select: none;
    font-weight: 800;
    transform: translate(-2vw);
    font-size: 0px;
    transition: all 0.7s ease-in-out;
}

.menuList{
    margin-top: 5px;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    padding: 8px;
    align-items: center;
    color:var(--font-color);
}

.menuList:hover{
    background-color: var(--menu-item-hover-color);
    color:var(--menu-item-hover-font-color);
    border-radius: 10px;
}

.menuListActive{
    background-color: var(--menu-item-hover-color);
    color:var(--menu-item-hover-font-color);
    border-radius: 10px;
}

.menuItemIcon{
    margin-top: 2px;
}

.menuItemIcon:hover{
    color:var(--menu-item-hover-font-color);
    background-color: var(--menu-item-hover-color);
}

.menuItemName{
    user-select: none;
    font-size: 15px;
    font-family:Arial, Helvetica, sans-serif;
    letter-spacing: 0.009rem;
    padding-left: 5%;
    margin-top: -1px;
    transition: all 0.7s ease-in-out,color 0s linear;
}

.menuItemNameHidden{
    user-select: none;
    transform: translate(-2vw);
    font-size: 0px;
    transition: all 0.7s ease-in-out,color 0s linear;
}

.showSubnavIcon{
    transform: rotate(90deg);
    font-weight: 700;
    font-family: 'Courier New', Courier, monospace;
    user-select: none;
    transition: all 0.5s linear, color 0s linear;
}

.showSubnavIconOpen{
    transition: all 0.5s linear, color 0s linear;
    font-weight: 700;
    font-family: 'Courier New', Courier, monospace;
    user-select: none;
}

.subnavOpenV2{
    display: grid;
    transition: all 0.4s linear;
}
.subnavCloseV2{
    display: grid;
    transition: all 0.4s linear;
    font-size: 0px;
}
.subnavOpenTextV2{
    font-size: 13px;
    margin-top: 5px;
    margin-left: 15%;
    margin-right: 15%;
    padding: 6px;
    color:var(--font-color);
    user-select: none;
    text-decoration: none;
    transition: all 0.4s linear, background-color 0s linear, color 0s linear, border-radius 0s linear;
}
.subnavOpenTextV2:hover{
    border-radius: 10px;
    color:var(--menu-item-hover-font-color);
    background-color: var(--menu-item-hover-color);
}

.subnavCloseTextV2{
    transition: all 0.4s linear, background-color 0s linear, color 0s linear, border-radius 0s linear;
    font-size: 0px;
    margin-left: 15%;
    margin-right: 15%;
    padding: 0px;
    color:var(--font-color);
    user-select: none;
    text-decoration: none;
}

.menuContainer{
    position: fixed;
    margin-top: -42.5px;
    background-color: rgba(0, 0, 0, 0);
    color:white;
    margin-left: 65px;
    z-index: 100000;
    padding: 20px;
    border-radius: 5px;
}

.menuHover{
    margin-left: -5px;
    margin-top: -23px;
    word-wrap: normal;
    white-space: nowrap;
    display: grid;
    padding: 5px;
    border-radius: 5px;
    color: var(--font-color);
    background-color: var(--sidebar-bg-color);
    box-shadow: 0 0 5px 1px grey;
}

.subnavClosedHover{
    min-width: 100px;
    word-wrap: normal;
    white-space: nowrap;
    display: grid;
    padding: 10px;
    margin-top: -42.5px;
    border-radius: 5px;
    color: var(--font-color);
    background-color: var(--sidebar-bg-color);
    box-shadow: 0 0 5px 1px grey;
}

.subnavItemSelect{
    margin-top: 2%;
    font-size: 13px;
    padding: 10px;
    color:var(--font-color);
    user-select: none;
    text-decoration: none;
}

.subnavItemSelect:hover{
    border-radius: 10px;
    color:var(--menu-item-hover-font-color);
    background-color: var(--menu-item-hover-color);
}

.profilebar{
    position: absolute;
    bottom: 6%;
    margin-top: 5px;
    width: 85%;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    padding: 5px;
    align-items: center;
    color:var(--font-color);
}
.profilebar:hover{
    background-color: var(--menu-item-hover-color);
    color:var(--menu-item-hover-font-color);
    border-radius: 10px;
}

.profileLogo{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height:30px;
    width:30px;
    user-select: none;
    border:1px solid var(--font-color);
}

.profileLogo:hover{
    border:1px solid var(--menu-item-hover-font-color);
    color:var(--menu-item-hover-font-color);
    background-color: var(--menu-item-hover-color);
}

.profileThreeDotButton{
    font-weight: 700;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    user-select: none;
    transition: all 0.5s linear, color 0s linear;
}

.profileThreeDotButton:hover{
    background-color: var(--hamburger-icon-hover-color);
    color:var(--menu-item-hover-font-color);

}

.profileContainer{
    position: fixed;
    bottom: 3%;
    background-color: rgba(0, 0, 0, 0);
    color:white;
    margin-left: 65px;
    z-index: 100000;
    padding: 20px;
    border-radius: 5px;
}

.profileClosedHover{
    min-width: 100px;
    word-wrap: normal;
    white-space: nowrap;
    display: grid;
    padding: 10px;
    border-radius: 5px;
    color: var(--font-color);
    background-color: var(--sidebar-bg-color);
    box-shadow: 0 0 5px 1px grey;
}


@media screen and (max-width: 600px) {
    .sidebarContainerV2Close{
        margin-left: -65px;
    }
}