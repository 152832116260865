:root{
    --container-bg-color: rgb(255, 255, 255);
    --container-shadow-color: rgb(159, 80, 0);
    --container-shadow-color-hover: rgb(228, 106, 0);
    --container-shadow-color-hover-lighter:rgb(255, 168, 68);
    --container-shadow-color-hover-more-lighter:rgb(251, 216, 177);
    --tab-font-color: rgb(117, 117, 117);
    --tab-font-color-hover: rgb(80, 80, 80);
    --table-headers-background: rgb(230, 230, 230);
    --font-color:white;
}

.dashboardStatsRowOne{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    /* justify-content: center; */
}
.dashboardV2ColumnOne{
    width: 80%;
}
.dashboardV2ColumnOneRowOne{
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 10px;
}
.dashboardV2ColumnTwo{
    width: 20%;
}
.dashboardStatsColumnTwoRowOne{
    width: 100%;
}
.dashboardStatsColumnTwoRowTwo{
    width: 100%;
}
.dashboardContainerV2{
    min-height: calc(100vh - 60px);
    margin-top: -10px;
    background-color: white;
    padding-right: 20px;
    padding-left: 0px;
    padding-top: 10px;
    display: flex;
}

.dashboardV2Heading{
    font-size: 32px;
}
.dashboardStatsContainer{
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.dashboardStatsWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 135px;
    min-width: 80px;
    width: calc(18% - 40px);
    border-radius: 25px;
    margin: 10px;
    position: relative;
    box-shadow: 0 0 5px 1px rgb(150,150,150);
    background: linear-gradient(to bottom right,white 10%,var(--container-shadow-color-hover-more-lighter) 100%);
    transition: all 0.3s linear;
}
.dashboardV2ColumnOneRowOneTwo{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 25px;
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    width:100%;
    box-shadow: 0 0 5px 1px rgb(150,150,150);
    background: linear-gradient(to bottom right,white 10%,var(--container-shadow-color-hover-more-lighter) 100%);
    transition: all 0.3s linear;
}
.dashboardV2ColumnOneRowOneTwoWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 180px;
    min-width: 80px;
    width: calc(18% - 35px);
    border-radius: 25px;
    margin: 10px;
    position: relative;
    transition: all 0.3s linear;
}
.spinned{
    transform: rotateY(180deg);
}
.hideElements{
    opacity: 0;
    transform: scale(0);
    transition: all 0.3s linear;
}

.dashboardStatsWrapper:hover{
    transition: all 0.3s linear;
}

.dashboardStatsRowOneColumnTwo{
    width: 20%;
}

.dashboardAccountManagerWrapper{
    min-height: 250px;
    min-width: 200px;
    margin: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 25px;
    position: relative;
    box-shadow: 0 0 5px 1px rgb(150,150,150);
    background: linear-gradient(to bottom right,white 10%,var(--container-shadow-color-hover-more-lighter) 100%);
    transition: all 0.3s linear;
    z-index: 2;

}
.accountManagerLabel{
    font-size: 20px;
    margin-bottom: 20px;
}
.accountManagerLogo{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    box-shadow: 0 0 5px 1px var(--container-shadow-color-hover-lighter);
    border-radius: 15px;
    color:var(--container-shadow-color-hover-lighter);
}
.accountManagerName{
    margin-left: 10px;
    font-size: 16px;
}
.accountManagerContactValue{
    font-size: 13px;
}

.fifteenDaysGraph{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    z-index: 1;
}
.fifteenDaysGraphWrapper{
    width: 95%;
}
.topStatsContainer{
    display: flex;
    flex-direction: column;
    min-height: 40vh;
    min-width: 150px;
    width: 100%;
    /* padding: 10px; */
    border-radius: 25px;
    position: relative;
    margin-top: 20px;
    box-shadow: 0 0 5px 1px rgb(150,150,150);
    background: linear-gradient(to bottom right,var(--container-shadow-color-hover-more-lighter) 10%,white 90%);
    transition: all 0.3s linear;
}
.topTabs{
    padding:5px;
    margin: 10px;
    font-size: 14px;
    /* background-color: var(--container-shadow-color-hover-lighter); */
    border-radius: 25px;
    transition: all 0.3s linear;
    user-select: none;
    cursor: pointer;
}
.topTabs:first-child{
    margin-right: 15px;
}
.activeTopTab{
    transition: all 0.3s linear;
    transform: scale(1.05);
    box-shadow: 0 0px 5px 1px var(--container-shadow-color-hover-lighter);
}
.dashboardTopRow:hover{
    background-color: var(--container-shadow-color-hover);
}

.dashboardStatsRowThree{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 14%;
}

.dashboardActiveStatsWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100px;
    min-width: 150px;
    width: 18%;
    border-radius: 25px;
    margin: 10px;
    position: relative;
    box-shadow: 0 0 5px 1px rgb(150,150,150);
    background: linear-gradient(to bottom right,white 10%,var(--container-shadow-color-hover-more-lighter) 100%);
    transition: all 0.3s linear;
}
.dashboardActiveOffers{
    font-size: 32px;
}
.dashboardStatsRowTwo{
    padding-bottom: 30px;
}

.customizationDashboardPopup{
    height: 75vh;
    width: 60vw;
    background-color: white;
    display: flex;
    align-items: center;
    border-radius: 15px;
    box-shadow: 0 0 5px 2px grey;
}
.customizationDashboardDataWrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px;
    width: 50%;
}

.customizationDashboardDataContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    transform: scale(0.8);
    margin-left: -8%;
}
.customizationDashboardDataLabel{
    font-weight: 500;
}
.customizationDashboardDataValue{
    margin-top: 3px;
    width: 90%;
}
@media screen and (max-width: 900px) {
    .dashboardContainerV2{
        flex-wrap: wrap;
    }
    .dashboardStatsContainer{
        width: 100%;
    }
    .dashboardV2ColumnOne{
        width: 100%;
    }
    .dashboardV2ColumnOneRowOne{
        justify-content: center;
    }
    .dashboardV2ColumnTwo{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
    }
    .dashboardStatsWrapper{
        min-width: 180px;
    }
    .dashboardV2ColumnOneRowOneTwoWrapper{
        min-width: 180px;
    }
    .dashboardStatsRowOneColumnTwo{
        width: 100%;
    }
    .dashboardAccountManagerWrapper{
        margin-left: 10px;
    }
    .fifteenDaysGraph{
        width: 100%;
        margin-top: 0%;
    }
    .fifteenDaysGraphWrapper{
        width: 100%;
    }
    .topStatsContainer{
        width: 100%;
        margin-left: 10px;
    }
    .dashboardStatsRowThree{
        justify-content: center;
        margin-top: 0;
        padding-left: 0%;
        width: 100%;
    }
    .customizationDashboardPopup{
        width: 95vw;
    }
    .customizationDashboardImage{
        display: none;
    }
    .customizationDashboardDataWrapper{
        width: 100%;
    }
}