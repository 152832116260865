:root{
    --container-shadow-color-hover: rgb(228, 106, 0);
    --container-shadow-color-hover-lighter:rgb(255, 168, 68);
}
.row{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
}

.column{
    display: grid;
}

.columnData{
    display: grid;
    background-color: rgb(252, 252, 252);
    margin-top: 1%;
    width: 100%;
    border-radius: 2px;
    box-shadow: 0 0 10px 2px var(--container-shadow-color-hover-lighter);
    margin-bottom: 1%;
}

.columnData:hover{
    box-shadow: 0 0 10px 2px var(--container-shadow-color-hover);
}


.tabsContainer{
    display: flex;
    width: 100%;
    /* height: ; */
    border-bottom: 1px solid grey;
    transition: all 1s ease;
}

.tabPanes{
    text-align: center;
    width: 70px;
    padding:20px;
    text-decoration: none;
    color: black;  
}


.loaderWrapper{
    width: 100%;
    height: 100%;
}


.loader{
    display: flex;
    width: 100%;
    height: 40vh;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.dataTable{
    margin-top: 2%;
    width: 100%;
    z-index: 0;
    font-size: 12px;
}

.dataColumnHead{
    text-align: center;
    padding: 6px;
    background-color: rgb(230, 230, 230);
    cursor: pointer;
}
.dataColumnHead:hover{
    background-color: rgb(190,190,190);
}

.dataColumn{
    text-align: center;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: default;

}
.dataColumn:hover{
    background-color: rgb(240,240,240);
}


.dataColumnTotal{
    text-align: right;
    padding: 5px;
}
.dataColumnTotal:hover{
    background-color: rgb(110,110,110);
}
.datePicker{
    background-color: aqua;
    z-index: 2;
    position: absolute;
    margin-top: 2%;
    margin-left: -4%;
    transform: scale(0.85);
}

/* Popup style */
.popup-box {
    position: fixed;
    background: #00000013;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10000;
    backdrop-filter: blur(1px);
  }
   
.box-offers{
    position: relative;
    background-color: rgb(255, 255, 255);
    margin: auto;
    height: 90vh;
    max-height: 90vh;
    overflow: auto;
    /* width: 70%; */
    margin-top: 5vh;
    margin-left: 15%;
    margin-right: 15%;
    border-radius: 4px;
    z-index: 100; 
    box-shadow: 1px 1px 10px 1px rgb(92, 92, 92);
  }

.box-response{
    position: relative;
    background-color: rgb(255, 255, 255);
    margin: auto;
    height: 30%;
    max-height: 90vh;
    margin-top: calc(100vh - 80vh - 20px);
    margin-left: 25%;
    margin-right: 25%;
    /* background: #1222358c; */
    border-radius: 4px;
    /* border: 1px solid #999; */
    /* overflow: hidden; */
    z-index: 100; 
    box-shadow: 1px 1px 10px 1px rgb(92, 92, 92);
  }
.popup-offer-header{
    position: relative;
    font-size: 20px;
    font-weight: 700;
    padding-left: 3%;
    padding-top: 15px;
    padding-bottom: 15px;
}
.popup-offer-results{
    display: flex;
    justify-content: space-between;
    padding-left: 3%;
    font-size: 12px;

}
.popupHeader{
    position: relative;
    /* background-color: rgb(255, 8, 0); */
    padding-left: 3%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
.popupData {
    position: relative;
    margin: auto;
    height: 50%;
    max-height: 90vh;
    /* background: #1222358c; */
    padding: 30px;
    overflow-y: auto;
    overflow-x: hidden;
  }
   
.close-icon-offers {
    content: 'x';
    cursor: pointer;
    position: fixed;
    color:rgb(0, 0, 0);
    right: calc(16%);
    top: calc(7vh - 2px);
    background: #ededed00;
    width: 25px;
    height: 25px;
    /* border-radius: 50%; */
    line-height: 20px;
    text-align: center;
    font-size: 20px;
    z-index: 100;
  }
  
.close-icon-response {
    content: 'x';
    cursor: pointer;
    position: fixed;
    color:rgb(0, 0, 0);
    right: calc(26%);
    top: calc(19vh);
    background: #ededed00;
    width: 25px;
    height: 25px;
    /* border-radius: 50%; */
    line-height: 20px;
    text-align: center;
    font-size: 20px;
    z-index: 100;
  }
.Active{
    color:orange;
    font-size: 11px;
}
.Inactive{
    color:rgb(66, 66, 66);
    font-size: 11px;
}

.offerPopupMiddleDataTab{
    height:100%;
    overflow-y:auto;
    margin-left:5%;
    margin-top:2%;
}

.offerPopupLeftColumn{
    justify-content:right;
    align-items:center;
    display:flex;
    font-size:13px;
    font-weight:600;
    color:rgb(100,100,100);
    width:15%;
}

.threeDotButton{
    transform: scale(0.8);
    transition: all 0.2s ease;
}
.threeDotButton:Active{
    transform: scale(0.6);
    transition: all 0.2s ease;
}

.threeDotMenu{
    display: grid;
    align-items: center;
    height:60px;
    width:150px;
    position: fixed;
    box-shadow: 1px 1px 5px 2px grey;
    background-color: rgb(255, 255, 255);
}

.threeDotMenuItems{
    display: flex;
    /* align-items: center; */
    text-align: center;
    align-items: center;
    padding-left: 20%;
    font-size:12px;
    height: 30px;
    width: 80%;
    cursor: pointer;
}

.threeDotMenuItems:hover{
    font-size:12px;
    background-color: rgb(255, 240, 200);
}

.offerNotFetched{
    background:green;
    width:20px;
    font-size:20px;
    color:white;
    cursor:pointer;
}

.offerFetched{
    background:rgb(141, 141, 141);
    width:20px;
    font-size:20px;
    color:white;
    cursor:pointer;
    pointer-events: none; 
}

.platformIcon{
    width: 150px;
    height: 150px;
    box-shadow: 0 0 10px 0.01px rgb(150,150,150);
    margin: 2%;
    transition: all 0.3s ease-in-out;
}

.platformIcon:hover{
    box-shadow: 0 0 14px 0.01px rgb(255, 110, 14);
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
}

.platformIconSelected{
    width: 150px;
    height: 150px;
    transform: scale(1.1);
    box-shadow: 0 0 14px 0.01px green;
    transition: all 0.3s ease-in-out;
    margin: 2%;
}

.button-10 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
    border-radius: 6px;
    border: none;
    transform: scale(0.75);
    color: #fff;
    background: linear-gradient(180deg, #4B91F7 0%, #367AF6 100%);
     background-origin: border-box;
    box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
    user-select: none;
    -webkit-user-select: none;
    text-decoration: none;
    touch-action: manipulation;
  }
  
  .button-10:focus {
    box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
    outline: 0;
  }

  .demandOfferPopupContainer{
      position: fixed;
      top:0;
      left:0;
      z-index:1000;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.166);
      display: flex;
      justify-content: center;
      align-items: center;
  }

.demandOfferPopupWrapper{
    height: 80vh;
    width:70vw;
    background-color: white;
}
.demandOfferPopupHeader{
    position: sticky;
    top:0;
    height: 30px;
    display: flex;
    padding: 10px;
    padding-left: 20px;
    font-weight: 600;
    border-bottom: 1px solid rgb(150,150,150);
    padding-bottom: 1px;
    justify-content: space-between;
}
.demandOfferPopupData{
    height: calc(80vh - 50px);
    overflow-y: auto;
}
.demandOfferPopupDataFlow{
    display: flex;
    margin-bottom: 2px;
    margin-left: 5%;
    margin-right: 5%;
    border-bottom: 1px solid rgb(200,200,200);
}
.demandOfferPopupDataKey{
    /* background-color: rgb(220,220,220); */
    width: 25%; 
    padding:10px;
    font-size: 13px;
    font-weight: 700;
}
.demandOfferPopupDataValue{
    /* background-color: rgb(248, 248, 248); */
    width: 75%; 
    padding:10px;
    font-size: 13px;
    overflow: auto;
}

.demandOffersPopupInnerTable{
    display:flex;
    overflow: auto;
}
.demandOffersPopupInnerKeys{
    padding: 5px;
    padding-right: 15px;
    max-height: 20px;
    min-height: 20px;
    border-right: 1px solid rgb(220,220,220);
    border-bottom: 1px solid rgb(220,220,220);
    border-left: 1px solid rgb(220,220,220);
    font-weight: 600;
    font-size: 12px;
}
.demandOffersPopupInnerValues{
    padding: 5px;
    max-height: 20px;
    min-height: 20px;
    border-right: 1px solid rgb(220,220,220);
    border-bottom: 1px solid rgb(220,220,220);
    font-size: 11px;
    padding-left: 10px;
    padding-right: 10px;
}