:root{
    --container-bg-color: rgb(255, 255, 255);
    --container-shadow-color: rgb(159, 80, 0);
    --container-shadow-color-hover: rgb(228, 106, 0);
    --container-shadow-color-hover-lighter:rgb(255, 168, 68);
    --container-shadow-color-hover-more-lighter:rgb(251, 216, 177);
    --tab-font-color: rgb(117, 117, 117);
    --tab-font-color-hover: rgb(80, 80, 80);
    --table-headers-background: rgb(230, 230, 230);
    --font-color:white;
}

.rightBarMainContainer{
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    height: 0px;
    align-items: flex-start;
    z-index: 100000;
    transition: all 0.3s linear;
}
.rightBarOpenTab{
    margin-top: 80px;
    min-height: 50px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    background-color: var(--container-shadow-color-hover);
    color: white;
}

.rightBarContainer{
    margin-top: 50px;
    min-height: 100vh;
    max-height: calc(90vh);
    overflow-y: auto;
    width: 300px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.affiliateLogin{
    margin: 10px;
    min-height: 50px;
    min-width: 200px;
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 10px 2px grey;
    color: var(--container-shadow-color-hover);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.affiliateInfo{
    margin: 10px;
    min-height: 50px;
    min-width: 200px;
    padding-bottom: 20px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 10px 2px grey;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1;
}

.quickLinks{
    margin: 10px;
    min-height: 50px;
    min-width: 200px;
    padding-bottom: 20px;
    font-size: 18px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 10px 2px grey;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 0;
}

.accountManagerLogo{
    
}
@media screen and (max-width: 600px) {
    
}