.activateUserContainer{
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.activateLeftUserContainer{
    width: 60%;
}
.activateRightUserContainer{
    display: flex;
    flex-direction: column;
    height: calc(80% - 80px);
    width: calc(33% - 80px);
    position: absolute;
    right: 3%;
    background-color: rgb(255, 247, 241);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 30px;
}
@media screen and (max-width: 800px) {
    .activateUserContainer{
        justify-content: center;
    }
    .activateLeftUserContainer{
        display: none;
    }   
    .activateRightUserContainer{
        height: calc(90% - 60px);
        width: calc(90% - 60px);
        right: 0%;
        position: relative;    
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
}