.activeSummaryTabContainer{
    position: fixed;
    height: 100vh;
    width: 100vw;
    top : 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
}
.activeSummaryTabWrapper{
    margin-top: 2vh;
    margin-left: 4vw;
    height: 83vh;
    width: 90vw;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 5px 5px rgb(220,220,220);
}

.activeSummaryHeaderRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    height: 30px;
    border-bottom: 1px solid rgb(200, 200, 200);
}
.activeSummaryTabData{
    height: calc(83vh - 50px);
    overflow: auto;
}

.openedTabsRowContainer{
    position: fixed;
    width: max-content;
    height: 40px;
    bottom: 20px;
    right: 10px;
    z-index: 1002;
    display: flex;
    overflow-x: auto;
}

.openedTabWrapper{
    width: 230px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 5px;
    background-color: rgb(193, 204, 209);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.openedTabLabel{
    width: calc(100% - 50px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
}

.summaryActiveTabData{
    margin: 10px;
    margin-left: 20px;
    margin-right: 20px;
}