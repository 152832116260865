.dbCardContainer{
    border: 2px solid rgb(225,225,244);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}
.dbCardLabel{
    font-size: 16px;
    font-weight: 500;
    color:rgb(100,100,100);
}
.dbCardValue{
}

.instancePopupContainer{
    position: fixed;
    top:0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.42);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}
.instancePopupWrapper{
    min-height: 30vh;
    width: 70vw;
    background-color: white;
    /* padding: 10px; */
}

.animatedValue{
    font-weight: 500;
    font-size: 32px;
    
}