.loginPage{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    min-height: 100vh;
}

.mainBox{
    position: absolute;
    z-index: 100;
    width: 65%;
    height: 85%;
    display: flex;
    border-radius: 10px;
    box-shadow: 0px 0px 30px 2px rgb(66, 55, 44);
    transition: all 0.5s ease-in-out; 
}

.mainBox:hover{
    transform: scale(1.03);
    -webkit-transform: scale(1.03);
    /* width: 77.5%;
    height: 85%;
    top:7.5%;
    left: 8.75%; */
    transition: all 0.5s ease-in-out; 
    filter: blur(-200px);
}

.leftSideContainer{
    background: white;
    display: grid;
    background-color: orange;
    height: 100vh;
    width: 55vw;
}

.rightSideContainer{
    height: 100vh;
    width: 40vw;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    background-color: white;

}

.leftSideWrapper{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 60vw;
    background-color: orange;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.rightSideWrapper{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 40vw;
    background-color: white;
    display: flex;
    justify-content:center;
    overflow-y :auto;
}

.login-wrapper{
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.companylogo{
    margin-top: 5%;
    cursor:default;
}

.loginLabel{
    font-size: 20px;
}

.emailTextBox{
    width: 80%;
}

.passwordTextBox{
    margin-top: 3vh;
    margin-bottom: 3vh;
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
}

.submitButton{
    margin-top: 6vh;
    height:40px;
    width: 80%;
    background-color: orange;
    font-size: 15px;
    border: 2px solid white;
}

.submitButton:hover{
    background-color: rgb(223, 145, 0);
    transition: all 0.2s ease-in-out; 
}
.submitButton:active{
    border:2px solid black;
}

.showError{
    visibility: visible;
    color: red;
    font-size: 12px;
}

.hideError{
    font-size: 12px;
    visibility: hidden;
}
.loginDoodle{
    height: 40%;
}
.leftSideDetails{
    height: 100%;
    width : 50;
}

/* 600px when viewport enabled */
@media only screen and (max-device-width: 600px) {
    .loginPage{
        padding-top: 25%;
        padding-bottom: 30%;
    }
    .mainBox{
        display:flex;
        flex-wrap: wrap;
        min-height: 100%;
        width: 85%;
        height: max-content;
        border-radius: 5px;
    }
    .leftSideWrapper{
        width: 85vw;
        border-radius: 0px;
    }
    .rightSideWrapper{
        width: 100%;
        border-radius: 0px;
        padding-bottom: 5%;
    }
    .loginDoodle{
        height: 40%;
    }
    .leftSideDetails{
        height: 50vh;
        padding: 10%;
    }
    .login-wrapper{
        padding-top: 20%;
    }
    .emptyContainer{
        height: 200px;
    }
}
