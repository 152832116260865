.v2PageContainer{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 70px);
    margin-top: -10px;
    overflow: auto;
    position: relative;
}
.v2StickRows{
    position: sticky;
    left: 0px;
    width: calc(100% - 30px);
    z-index: 100;
    padding: 15px ;
    padding-top: 10px;
}
.v2Title{
    font-family: Oswald;
    font-size: 30px;
    margin-bottom: 10px;
}
.v2StatsOptionsFlex{
    display: flex;
    padding-top: 7px;
    align-items: flex-start;
    overflow: auto;
    padding-bottom: 7px;
}
.v2StatsOptionContainer{
    display: flex;
    padding: 5px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 2px solid transparent;
    cursor: pointer;
}
.v2StatsOptionsLabel{
    color: #7a7a7a;
    text-align: center;
    font-family: Kanit;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-wrap: nowrap;
    overflow-wrap: nowrap;
}
.v2StatsOptionContainerActive{
    border-radius: 136px;
    border: 2px solid var(--ambient-color);
    background: var(--ambient-color-95-percent-lighter);
}
.v2StatsOptionsLabelActive{
    color: var(--ambient-color);
}
.css-101bmv6-control{
    box-sizing: border-box !important;
}
