.demographyContainer{
    background-color: rgb(230,230,230);
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    height: calc(100vh - 70px);
    margin-top: -10px;
}
.demographyRouterRow{
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    overflow: auto;
    background-color: rgb(250,250,250);
}
.demographyRouterItem{
    padding-left: 20px;
    padding-right: 20px;
    text-wrap:nowrap;
    height: 50px;
    width: fit-content;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    text-transform: uppercase;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
}
.demographyFilterRow{
    position: relative;
    margin: 20px;
    background-color: rgb(250,250,250);
    border-radius: 5px;
    border: 1px solid rgb(220,220,220);
    box-shadow: 0px 1px 1px 1px rgb(220,220,220);
    padding: 15px;
}

.demographyAddFilterRow{
    display: flex;
    align-items: center;
}

.valueSelectedChangesWrapper{
    height: 50px;
    background-color: var(--container-shadow-color-hover);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0px;
    left:0px;
    z-index: 1000;
}

.archivedOfferStats{
    position: absolute;
    top: -4px;
    left: 0px;
    margin-left: -57px;
    background: var(--container-shadow-color-hover);
    color: white;
    font-weight: 800;
    transform: scale(0.5) rotate(-45deg);
    padding: 8px;
    padding-left: 35px;
    padding-right: 40px;
    border-radius: 10px;
    font-size: 15px;
}