
.v2demographyDateRangeContainer{
    min-width: 140px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    min-height: 27px;
    font-size: 12px;
    padding-left: 35px;
    /* margin-top: 10px; */
    display: flex;
    align-items: center;
    font-weight: 500;
    color: rgb(50,50,50);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.1s all ease-in-out;
    cursor: default;
    position: relative;
}
.v2DateLabel{
    color: #252525;
    font-family: Kanit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -0px;
}
.v2demographyDateRangePicker{
    position: absolute;
    transform: scale(0.8);
    margin-top: -35px;
    transform-origin: left;
    z-index: 10000;
}
.v2demographyFilterAddRow{
    display: flex;
}
.v2demographFilterContainer{
    
}