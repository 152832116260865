.tableHeaderStatsV2{
    position:sticky;
    top:0;
    display: flex;
    background-color: white;
    overflow:auto;
    /* border-bottom: 1px solid rgb(220,220,220);
    border-top: 1px solid rgb(220,220,220); */
    border-left: 1px solid rgb(220,220,220);
    /* border-right: 1px solid rgb(220,220,220); */
    margin-left: 10px;
    margin-right: 10px;
}
.headerTileV2{
    padding:5px;
}

.tableValueStatsV2{
    background-color: white;
    overflow:auto;
    /* border-bottom: 1px solid rgb(220,220,220); */
    /* border-top: 1px solid rgb(220,220,220); */
    border-left: 1px solid rgb(220,220,220);
    /* border-right: 1px solid rgb(220,220,220); */
    margin-left: 10px;
    margin-right: 10px;
}