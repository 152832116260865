.insightsManik {
  max-width: 1200px;
  min-width: 1120px;
  margin: auto 4rem;
  padding: 1rem;
  text-align: start;
  /* font-family: "kanit", sans-serif; */
  font-weight: 400;
}

.icon {
  width: 1rem;
  height: 1rem;
  transform: translateY(0.1rem);
  margin-left:0.5rem;
}
.icon1 {
  width: 1.4rem;
  height: 1.4rem;
  margin-left: 0.2rem;
}
.packagebtn ::-webkit-scrollbar {
  height: 10px; /* Set the height of the scrollbar */
  width:10px;
}
.header1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.menu-img {
  margin-right: 1rem;
  margin-top: 0.2rem;
  width: 2rem;
}

.custom-calendar {
  background-color: #c63131;
  border: 1px solid #ccc;
  padding: 10px;
}
.mainNavbar {
  
  /* max-width: 1120px; */
  z-index: 1000;
  background-color:#FFFFFF ;
}
.navbar {
  /*padding: 1rem; */
  /* background-color:#E9E9E9; */
  z-index: 9999;
  border-radius: 10px;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .react-select-container {
  flex-grow: 1;
} */
/* 
.react-select__control {
  background-color: lightgrey;
  border: none;
}

.react-select__single-value {
  color: black;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__dropdown-indicator {
  color: black;
}

.react-select__menu {
  border-radius: none;
}

.react-select__option--is-focused {
  background-color: lightgrey;
}

.react-select__option--is-selected {
  background-color: darkgrey;
} */

.containerManik {
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 10px;
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
}

.title {
  font-size: 1.8rem;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
}

.team-wise-anatomy {
  aspect-ratio: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.team-wise-anatomy .title {
  align-items: start;
}

.monthly-synopsis,
.red-flag-advertisers {
  aspect-ratio: 2.5;
}

.team-wise-anatomy-graphs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  align-content: space-around;
}

.doughnut {
  min-height: 10rem;
  position: relative;
}
/*
.navbar input {
  color: #252525;
  border:1px solid white;
  text-align:center;
  display:inline-block;
  width:20%;
  font-family:kanit,sans-serif;
  font-size:400;
  background-color:#E9E9E9;
}*/
.example-custom-input {
  background-color: #f0f0f0;
  margin-right: 1rem;
  margin-left: 1rem;
  align-self: center;
  width:91.8%;
  justify-self: left;
  border: none;
  color: #252525;
  /* font-family: "kanit", sans-serif; */
  font-weight: 400;
  font-size: 16px;
}
.img2 {
  height: 1.2rem;
  width: 1.2rem;
  transform: translateY(0.05rem);
  margin-right: 0.6rem;
}
.img3 {
  height: 1rem;
  width: 1rem;
  transform: translateY(0.13rem);
  margin-left: 2rem;
}

.monthpickerManik {
  display: grid;
  position: relative;
  flex-basis: 24.25%;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-right:2px;
}
.react-datepicker__month {
  background-color: #ffffff;
  /* font-family: "kanit", sans-serif; */
  padding-left:"0rem";
  padding-right:"0rem";
 
}
.react-datepicker__month .react-datepicker__month-text:hover {
  background-color: #e0f1fb;
}
.react-datepicker {
  /* font-family: "kanit", sans-serif; */
  z-index: 2;
}
.react-datepicker__month .react-datepicker__month-text{
  width:3.77rem !important;
}
.react-datepicker__month .react-datepicker__month-text {
  /* margin:3rem; */
}
/*.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range)
{
  background-color:yellow;
  color:yellow;
  width:2.8rem;
}
.react-datepicker__month-text--keyboard-selected{
  background-color: red;
}
.react-datepicker__month-text--keyboard-selected{

}
.react-datepicker__month-text--selected, .react-datepicker__month-text--selected, */
.react-datepicker__month-text--keyboard-selected {
  background-color: #e0f1fb !important;
  color: #252525 !important;
}
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-range {
  background-color: #e0f1fb !important;
  color: #252525 !important;
}
.react-datepicker-year-header {
  background-color: #f0f0f0 !important;
  /* font-family: "kanit", sans-serif !important; */
}
.react-datepicker__month-text--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ) {
  background-color: #e0f1fb !important;
  color: #252525 !important;
}
.tablegraphimg_main {
  display: flex;
  justify-content: space-between;
}

.tablegraphimg img {
  padding-top: 0.3rem;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  padding-bottom: 0.3rem;
  width: 2rem;
  height: 2rem;
}
.tablegraphimg img:hover {
  background-color: #c7c7c7;
}
.selectedOption {
  background-color:  #e0f1fb; 
  border:6px solid white;
}

.selected img {
  padding-top: 0.3rem;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  padding-bottom: 0.3rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #c7c7c7;
  border-radius: 3px;
}
thead {
  font-weight: 800;
}

.tab {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
}
.tab th {
  /* font-family: "kanit", sans-serif; */
  font-size: 1.1rem;
  font-weight: 400;
  
  background-color: #CFE1EE;
  border: none;
}
.tab1 {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  position: relative;
}
.main_table1 {
  /* height: calc(90vh - 160px); */
  overflow: auto;
  position: relative;

}
.main_table_month{
  height:auto;
  overflow: auto;
  position:relative;
}
.tab1 th {
  /* font-family: "kanit", sans-serif; */
  font-size: 1.1rem;
  font-weight: 400;
 
  background-color: #cfe1ee;
  border: none;
}

.tab1 th:nth-child(2),
.tab1 td:nth-child(2) {
            position: sticky;
            left: 0;
            z-index:1;
        }
.tab1 td {
  height: 2.5rem;
  font-size: 0.8rem;
  text-align: right;
  padding-right: 2rem;
  word-wrap: break-word;
}
.tab1 thead,
.tab1 tfoot {
  position: sticky;
}
.tab1 thead {
  inset-block-start: 0;
  z-index:2;
}

.firstcol {
  text-align: left !important ;
}
.tab td {
  font-size: 1rem;
  font-weight: 400;
  text-align: right;
  padding-right: 2.5rem;
  padding-left:1.6rem !important;
}
.first_col {
  text-align: left;
  max-width: 8rem;
}
.table {
  padding: 0rem;
  border: 1px solid #f0f0f0;
  margin-bottom: 1rem;
  border-radius: 10px;
}
.main_table {
  display: flex;
  flex-direction: column;
  /* height: 26rem; */
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
}
.tabim {
  display: flex;
}

.last-row-addtable td {
  background-color:#0E6BA8;
  height:2rem;
  border-bottom: 1px solid #f0f0f0;
  font-weight: 400;
  font-size:0.8rem;
  color:#FFFFFF;
  padding-right: 4rem;


}
.last-row td {
  background-color: #cfe1ee;
  height: 3.6rem;
  border-bottom: 1px solid #f0f0f0;
  font-weight: 800;
  position: sticky;
  bottom:0;
}
.btnstyle {
  background-color: #f0f0f0 !important;
  font-family: "Oswald", sans-serif !important;
  font-size: 24px !important;
  color: #252525 !important;
}
.slider-container {
  margin-top: 1.2rem;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
 
  margin: none;
}
input[type="range"] {
  width: 65px;
  -webkit-appearance: none;
  appearance: none;
  height: 5px;
  background: white;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 5px;
  cursor: pointer;
}
.percentage-text {
  font-size: small;
  margin-top: none;
  color: #9a9a9a;
  /* font-family: "kanit", sans-serif; */
}
input[type="range"]::-webkit-slider-thumb {
  display: none;
}
input[type="range"] {
  margin-top: 0.5rem;
}

input[type="range"]::-moz-range-thumb {
  display: none;
}
.percentage-div {
  padding: 0.1rem 0.2rem 0.1rem 0.2rem;
  display: inline-block;
  min-width: 3.2rem;
  font-size: 1rem;
  margin-top: 0.1rem;
}
.drop {
  flex-basis: 25%;
  max-width: 25%;
}

.packagebtn {
  width: 100%;
  border: none;
  background-color: #f0f0f0;
  border-radius: 3px;
  height: 100%;
  /* font-family: "kanit", sans-serif; */
  font-size: medium;
}
.options {
  height: 2.5rem;
  text-align: left;
  /* font-family: "kanit", sans-serif; */
  font-weight: 400;
  padding-left: 1rem;
  padding-top: 1.2rem;


}
.options:hover {
  background-color: #e0f1fb;
}
.drop:last-child {
  border-radius: 10px;
}
.abovemain {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.6rem;
  position: sticky;
  top: 0;
  background-color: white;
  padding: 10px; 
}
.Aboveinput {
  height: 1.8rem;
  width: 16rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  border: 1.3px solid #737373;
  border-radius: 5px;
  padding-left:5px;
  
}
input::-webkit-input-placeholder {
  color:#737373; 
}
.packageInput {
  height: 1.2rem;
  width: 5rem;
  border: 1px solid;
  border-radius: 5px;
  /* font-family: "Nunito", sans-serif; */
  border: none;
 
  outline: none;
  font-size: 0.8rem;
}

.packageInput:focus {
  outline: none;
}

.allcell {
  min-width: 1rem;
  height: 3.5rem;
}
.addcolumn {
  display: flex;
  justify-content: space-between;
}
.addcolumn2 {
  display: flex;
  justify-content: space-between;
}
.oddcolour {
  background-color: #e7f4fb;
}
/* Styles for the dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Styles for the dropdown button */
.dropdown button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

/* Styles for the dropdown content container */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Styles for the input field */
.dropdown-content input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 3px;
}

/* Styles for the "Add" button */
.dropdown-content button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin: 5px 0;
}

/* Styles for the checkbox labels */
.addcolumnoption {
  display: flex;
  width: 30px;
  height: 30px;
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.pagination li {
  margin-right: 10px; /* Adjust the spacing between page numbers as needed */
}

.pagination li.active {
  font-weight: bold;
}
.page-number {
  color: #252525;
}
.page-number:hover {
  color: #252525;
}
.activeManik {
  background-color: #cfe1ee;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 10px;
  text-align: center;
}
.pagination .page-number img {
  width: 20px; /* Adjust the width as needed */
  height: auto; /* Maintain the aspect ratio */
}

.monthHeadDiv {
  display: flex;
  justify-content: space-between;
  flex:2;
}
.monthhead {
  font-family: "oswald", sans-serif;
  color: #252525;
  font-size: 1.4rem;
  /* font-weight: 800; */
  margin-top: 0.5rem;
  margin-left:2px;
}
.doughnutfooter {
  display: flex;
  justify-content: space-around;
  height: 20%;
  width: 100%;
  padding: 0rem 0rem 0.5rem;
}
.doughTeamTotal {
  display: flex;
  margin-left: 0.3rem;
  /* margin-right: 0.3rem; */
  color: #ffffff;
  justify-content: center;
  align-items: center;
}
.sticky {
  position: sticky;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  margin-top: 10%;
  height: 100%;
}
.teamName {
  color: #252525;
  font-family: "Oswald", sans-serif;
  font-size: 2rem;
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.team1,
.team2,
.team3 {
  display: inline-block;
  position: relative;
  top: 240%;
}
.team1 {
  left: -32%;
}
.team2 {
  left: -14.9%;
}
.team3 {
  left: 2.3%;
}

@media screen and (min-width: 667px) and (max-width: 962px) {
  .team1,
  .team2,
  .team3 {
    display: inline-block;
    position: relative;
    top: 270%;
  }
  .team1 {
    left: -13%;
  }
  .team2 {
    left: 18%;
  }
  .team3 {
    top: 930%;
    left: -26%;
  }
}
@media screen and (min-width: 963px) and (max-width: 1150px) {
  .team1,
  .team2,
  .team3 {
    display: inline-block;
    position: relative;
    top: 240%;
  }
  .team1 {
    left: -28%;
  }
  .team2 {
    left: -12%;
  }
  .team3 {
  
    left: 4%;
  }
}
@media screen and (min-width: 900px) and (max-width: 1070px) {
  .react-datepicker__month .react-datepicker__month-text{
  width:3.26rem !important;
 }

}
@media screen and (min-width: 1080px) and (max-width: 1140px) {
  .react-datepicker__month .react-datepicker__month-text{
  width:3.5rem !important;
 }

}

.crossContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-right: 0.5rem;
}

.cross {
  position: relative;
  z-index: 2;
  top: -20px;
  left: 30px;
}

.parent {
  display: flex;
  width: 100%;
  flex-wrap:wrap;
}
.child {
  flex: 1;
  /* width: 48%; */
}

.headingTitle{
  font-size: 1.25rem;
  font-weight: 400;
  background:#0E6BA8;
  font-family: "Oswald", sans-serif;
  padding: 10px;
  color: #FFFFFF;
}

.formLabel{
  color: #2E5077;
  /* font-family: "kanit", sans-serif; */
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 10px ;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.formInput{
  color: #2E5077;
  /* font-family: "kanit", sans-serif; */
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px ;
  padding: 0.2rem;
  border-radius:5px;
  border:1px solid #2E50774D
}
.formButton{
  font-size: 1rem;
  font-weight: 400;
  background:#0E6BA8;
  /* font-family: "kanit", sans-serif; */
  padding: 4px;
  color: #FFFFFF;
  width: 25%;
  border: none;
  border-radius: 5px;
  margin-top: 0.5rem;
}
.form{
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forecast{
  display:flex;
  justify-content:space-between;
  padding:1rem;
}


.forecast-labels{
  color:#A5A5A5;
  font-size: 20px;
  /* font-family: "kanit","sans-sarif"; */
  font-weight: 500;

}

.forecast-values{
  color:#252525;
  font-size: 20px;
  font-weight: 600;
}

.title-info{
  font-size:11px;
  color: #A5A5A5;
  font-family: system-ui;
}

.team-title{
  position:absolute;
  top:25%;
  left:39%;
  font-weight:400;
  font-size:1.8rem;
  font-family:"Oswald", sans-serif;
}