
.demographyDateRangeContainer{
    border-bottom: 1px solid rgb(180,180,180);
    min-width: 140px;
    min-height: 27px;
    font-size: 12px;
    margin-top: 10px;
    font-weight: 500;
    color: rgb(50,50,50);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.1s all ease-in-out;
    cursor: default;
    position: relative;
}
.demographyDateRangePicker{
    position: absolute;
    transform: scale(0.8);
    margin-top: -2vh;
    transform-origin: left;
    z-index: 10000;
}
.demographyFilterAddRow{
    display: flex;
}
.demographFilterContainer{
    
}