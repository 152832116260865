:root{
    --container-shadow-color-hover: rgb(228, 106, 0);
    --container-shadow-color-hover-lighter:rgb(255, 168, 68);
}
.row{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
}

.column{
    display: grid;
}

.columnData{
    display: grid;
    background-color: rgb(252, 252, 252);
    margin-top: 1%;
    width: 100%;
    border-radius: 2px;
    box-shadow: 0 0 10px 2px var(--container-shadow-color-hover-lighter);
    margin-bottom: 1%;
}

.columnData:hover{
    box-shadow: 0 0 10px 2px var(--container-shadow-color-hover);
}


.tabsContainer{
    display: flex;
    width: 100%;
    /* height: ; */
    border-bottom: 1px solid grey;
    transition: all 1s ease;
}

.tabPanes{
    text-align: center;
    width: 70px;
    padding:20px;
    text-decoration: none;
    color: black;  
}


.loaderWrapper{
    width: 100%;
    height: 100%;
}


.loader{
    display: flex;
    width: 100%;
    height: 40vh;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.dataTable{
    margin-top: 2%;
    width: 100%;
    z-index: 0;
    font-size: 12px;
}

.dataColumnHead{
    text-align: center;
    padding: 6px;
    background-color: rgb(230, 230, 230);
    cursor: pointer;
}
.dataColumnHead:hover{
    background-color: rgb(190,190,190);
}

.dataColumn{
    text-align: center;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: default;

}
.dataColumn:hover{
    background-color: rgb(240,240,240);
}


.dataColumnTotal{
    text-align: right;
    padding: 5px;
}
.dataColumnTotal:hover{
    background-color: rgb(110,110,110);
}
.datePicker{
    background-color: aqua;
    z-index: 2;
    position: absolute;
    margin-top: 2%;
    margin-left: -4%;
    transform: scale(0.85);
}

/* Popup style */
.popup-box {
    position: fixed;
    background: #00000013;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10000;
    backdrop-filter: blur(1px);
  }
   
.box{
    position: relative;
    background-color: rgb(255, 255, 255);
    margin: auto;
    height: 90%;
    max-height: 90vh;
    width: 90%;
    margin-top: 5vh;
    margin-left: 5%;
    margin-right: 25%;
    border-radius: 4px;
    z-index: 100; 
    box-shadow: 1px 1px 10px 1px rgb(92, 92, 92);
  }

.box-response{
    position: relative;
    background-color: rgb(255, 255, 255);
    margin: auto;
    height: 30%;
    max-height: 90vh;
    margin-top: calc(100vh - 80vh - 20px);
    margin-left: 25%;
    margin-right: 25%;
    /* background: #1222358c; */
    border-radius: 4px;
    /* border: 1px solid #999; */
    /* overflow: hidden; */
    z-index: 100; 
    box-shadow: 1px 1px 10px 1px rgb(92, 92, 92);
  }

.popupHeader{
    position: relative;
    /* background-color: rgb(255, 8, 0); */
    padding-left: 3%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
.popupData {
    position: relative;
    margin: auto;
    height: 30%;
    max-height: 90vh;
    /* background: #1222358c; */
    padding: 30px;
  }
   
.close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    color:rgb(0, 0, 0);
    right: calc(6%);
    top: calc(7vh - 2px);
    background: #ededed00;
    width: 25px;
    height: 25px;
    /* border-radius: 50%; */
    line-height: 20px;
    text-align: center;
    font-size: 20px;
    z-index: 100;
  }
  
.close-icon-response {
    content: 'x';
    cursor: pointer;
    position: fixed;
    color:rgb(0, 0, 0);
    right: calc(26%);
    top: calc(19vh);
    background: #ededed00;
    width: 25px;
    height: 25px;
    /* border-radius: 50%; */
    line-height: 20px;
    text-align: center;
    font-size: 20px;
    z-index: 100;
  }
.Active{
    color:orange;
    font-size: 11px;
}
.Inactive{
    color:rgb(66, 66, 66);
    font-size: 11px;
}

.offerPopupMiddleDataTab{
    height:100%;
    overflow-y:auto;
    margin-left:5%;
    margin-top:2%;
}

.offerPopupLeftColumn{
    justify-content:right;
    align-items:center;
    display:flex;
    font-size:13px;
    font-weight:600;
    color:rgb(100,100,100);
    width:15%;
}

.threeDotButton{
    transform: scale(0.8);
    transition: all 0.2s ease;
}
.threeDotButton:Active{
    transform: scale(0.6);
    transition: all 0.2s ease;
}

.threeDotMenu{
    display: grid;
    align-items: center;
    height:90px;
    width:150px;
    position: fixed;
    box-shadow: 1px 1px 5px 2px grey;
    background-color: rgb(255, 255, 255);
}

.threeDotMenuItems{
    display: flex;
    /* align-items: center; */
    text-align: center;
    align-items: center;
    padding-left: 20%;
    font-size:12px;
    height: 30px;
    width: 80%;
    cursor: pointer;
}

.threeDotMenuItems:hover{
    font-size:12px;
    background-color: rgb(255, 209, 183);
}

