
.manualRecheckfirstRow{
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: -10px;
    z-index: 999;
    position: relative;
}
.manualRecheckOfferStatusTable{
    font-weight: 500;
}
.manualRecheckDataTable{
    font-size: 12px;
    border: 1px solid grey;
    padding: 3px;
    margin-right: 3px;
    border-radius: 5px;
    margin-bottom: 2px;
}

.redirectionDataPopup{
    position: fixed;
    top:0;
    left:0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(91, 91, 91, 0.21);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.redirectionDataPopupWrapper{
    height: 75vh;
    width: 80vw;
    background-color: white;
    box-shadow: 0 0 5px 1px grey;
}
.redirectionDataPopupHeader{
    display: flex;
    justify-content: space-between;
    padding:10px;
    margin-left: 5px;
    margin-right: 5px;
    border-bottom: 1px solid rgb(200,200,200);
}
.redirectionDataTable{
    overflow: auto;
    max-height: 65vh;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;
}
