
.zoomedBackgroundV2{
    height: 100vh;
    width: 100vw;
    text-align: center;
    top:0;
    left:0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    backdrop-filter: blur(1px);
}
.zoomedV2{
    transition: all 0.2s ease-in;
    height: 90vh;
    width: 90%;
    background: white;
    box-shadow: 0 0 10px 1px grey;
    z-index: 10000;
}

.zoomedOut{
    height: 1px;
    width: 1px;
    position: fixed;
    visibility: hidden;
    transform: scale(1px,1px) translateX(-45vw) translateY(0);
    transition: all 0.1s ease-in;
}

.requestedTabActive{
    color:orange;
    font-weight: 700;
    padding: 2%;
    padding-top: 0%;
    cursor: pointer;
}

.requestedTabInactive{
    padding: 2%;
    padding-top: 0%;
    cursor: pointer;
}
.statsData{
    width:30%;
}

@media screen and (max-width: 600px) {
    .rowDashboard{
        flex-wrap: wrap;
    }
    .rowDashboard:nth-child(3){
        flex-wrap: wrap-reverse;
    }
}