:root{
    --top-bg-color: rgb(255, 119, 0);
    --font-color: rgb(255, 255, 255);
    --hamburger-icon-hover-color:rgb(186, 87, 0);
    --menu-item-hover-color:rgb(255, 255, 255);
    --menu-item-hover-font-color:black;
}

.topbarContainerV2{
    color:var(--font-color);
    height: 50px;
    width: 100%;
    z-index: 1000;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--top-bg-color);
    transition: background-color 0.7s ease-in-out;
}

.logoutButtonV2{
    user-select: none;
    padding-right: 2%;
    color:var(--font-color);
    cursor: pointer;
}

.companyNameV2{
    user-select: none;
    padding-left: 10px;
    padding-right: 2%;
    width: max-content;
    color:var(--font-color);
    cursor: default;
    font-weight: 500;
}

.notificationMenu{
    height: 400px;
    width: 300px;
    box-shadow: 0 0 5px 1px rgba(128, 128, 128, 0.277), 0 10px 20px 1px rgba(128, 128, 128, 0.277);
    background-color: white;
    z-index: 900000;
    position: absolute;
    left: -250px;
    top:35px;
    animation: notificationMenuShow 0.3s forwards;
    color: black;
    overflow: auto;
}

@keyframes notificationMenuShow {
    0% {
        transform-origin: top;
        transform: scaleY(0);
    }
    100% {
        transform: scaleY(1);
    }
}

.hideNotificationMenu{
    transform: scale(0);
    position: absolute;
}

.notificationItemWrapper{
    display: flex;
    align-items: center;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    position: relative;
}
.notificationItemWrapper:hover{
    background-color: var(--container-shadow-color-hover-more-lighter) !important;
    transition: all 0.2s ease-in-out;
}