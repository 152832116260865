:root{
    --container-shadow-color-hover: rgb(228, 106, 0);
    --container-shadow-color-hover-lighter:rgb(255, 168, 68);
}

.row{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
}

.column{
    display: grid;
}

.columnDataStats{
    display: block;
    background-color: rgb(252, 252, 252);
    margin-top: 1%;
    width: 100%;
    border-radius: 2px;
    box-shadow: 0 0 10px 2px var(--container-shadow-color-hover-lighter);
    margin-bottom: 1%;
}

.columnDataStats:hover{
    box-shadow: 0 0 10px 2px var(--container-shadow-color-hover);
}


.tabsContainer{
    display: flex;
    width: 100%;
    border-bottom: 1px solid grey;
    transition: all 1s ease;
    font-size: 13px;
}

.tabPanes{
    text-align: center;
    width: 70px;
    padding:20px;
    text-decoration: none;
    color: black;  
}


.loaderWrapper{
    width: 100%;
    height: 100%;
}


.loader{
    display: flex;
    width: 100%;
    height: 40vh;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.dataTable{
    margin-top: 2%;
    width: 100%;
    z-index: 0;
    font-size: 12px;
}

.dataColumnHead{
    text-align: center;
    padding: 6px;
}
.dataColumnHead:hover{
    background-color: rgb(190,190,190);
}

.dataColumn{
    text-align: center;
    padding: 5px;
}
.dataColumn:hover{
    background-color: rgb(240,240,240);
}

.dataColumnTotal{
    text-align: center !important;
    padding: 5px;
    padding-right: 0px !important;
}
.dataColumnTotal:hover{
    background-color: rgb(110,110,110);
}
.datePicker{
    background-color: aqua;
    z-index: 2;
    position: absolute;
    margin-top: 2%;
    margin-left: -4%;
    transform: scale(0.85);
}

.hiddenUrl{
    text-decoration: none;
    color: black;
}

.hiddenUrl:hover{
    text-decoration: underline;
}


.sortingOrder{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
}

.sortingOrder:hover{
    background-color: rgb(219, 219, 219);
}


.settingsButton{
    color:rgb(150,150,150)
}

.settingsButton:hover{
    color:rgb(125,125,125)
}

.settingsButton:active{
    color:rgb(100,100,100)
}


