
.filterRowContainer{
    display: flex;
    flex-wrap: wrap;
}

.filterItemLabel{
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
}
.filterItem{
    margin-right: 30px;
    margin-bottom: 30px;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input{
    font-size: 12px !important;
}