

.justify-center {
    justify-content: center;
}
.items-center {
    align-items: center;
}
.h-\[140px\] {
    height: 140px;
}
.mt-6 {
    margin-top: 1.5rem;
}
.mb-6 {
    margin-bottom: 1.5rem;
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.rounded-\[10px\] {
    border-radius: 10px;
}
.h-\[100\%\] {
    height: 100%;
}
.pt-6 {
    padding-top: 1.5rem;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.flex-col {
    flex-direction: column;
}
.flex {
    display: flex;
}
.text-\[\#331E38\] {
    --tw-text-opacity: 1;
    color: rgb(51 30 56 / var(--tw-text-opacity));
}

.text-\[16px\] {
    font-size: 16px;
    font-weight: inherit;
    margin:0;
}
.text-\[\#A5A5A5\] {
    --tw-text-opacity: 1;
    color: rgb(165 165 165 / var(--tw-text-opacity));
}
.text-\[12\.8px\] {
    font-size: 12.8px;
    font-weight: inherit;
    margin:0;
}
.text-\[\#331E38\] {
    --tw-text-opacity: 1;
    color: rgb(51 30 56 / var(--tw-text-opacity));
}
.text-\[\#0E6BA8\] {
    --tw-text-opacity: 1;
    color:rgba(14, 107, 168, 1);
    font-size: 1rem;
    font-weight: 500;
}
.text-\[32px\] {
    font-size: 32px;
    font-weight: inherit;
    margin:0;
}
.text-\[16px\] {
    font-size: 16px;
    font-weight: inherit;
    margin:0;
}
.text-\[\#DF0000\] {
    --tw-text-opacity: 1;
    color: rgb(223 0 0 / var(--tw-text-opacity));
}
.shadow-slate-400 {
    --tw-shadow-color: #94a3b8;
    --tw-shadow: var(--tw-shadow-colored);
}
.shadow-md {
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.rounded-\[10px\] {
    border-radius: 10px;
}
.justify-start {
    justify-content: flex-start;
}
.items-center {
    align-items: center;
}
.flex-col {
    flex-direction: column;
}
.w-\[45\%\] {
    width: 45%;
}
.h-\[390px\] {
    height: 390px;
}
.z-50 {
    z-index: 50;
}
.top-\[17\%\] {
    top: 17%;
}
.left-\[7\%\] {
    left: 7%;
}
.fixed {
    position: fixed;
}
.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}
.font-semibold {
    font-weight: 600;
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-start {
    text-align: start;
}
.grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid {
    display: grid;
}
.mt-1 {
    margin-top: 0.25rem;
}
.text-center {
    text-align: center;
}
.bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}

.calendar-day{
    font-weight: 500;
    border-width: 5px;
    border: 1.5px solid #E3E3E3;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
}
.border-\[\#E3E3E3\] {
    --tw-border-opacity: 1;
    border-color: rgb(227 227 227 / var(--tw-border-opacity));
}
.border {
    border-width: 1px;
}
.cursor-pointer {
    cursor: pointer;
}
.m-1 {
    margin: 0.25rem;
}
.col-span-1 {
    grid-column: span 1 / span 1;
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.border {
    border-width: 1px;
}
.border-b {
    border-bottom-width: 1px;
}
.border {
    border-width: 1px;
}
.items-center {
    align-items: center;
}
.flex {
    display: flex;
}
.items-center {
    align-items: center;
}
.justify-center {
    justify-content: center;
}
.justify-center {
    justify-content: center;
}
.items-center {
    align-items: center;
}
.flex {
    display: flex;
}
.mb-4 {
    margin-bottom: 1rem;
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.flex {
    display: flex;
}
.items-center {
    align-items: center;
}
.items-center {
    align-items: center;
}
.flex {
    display: flex;
}
.items-center {
    align-items: center;
}
.items-center {
    align-items: center;
}
.flex {
    display: flex;
}
.items-center {
    align-items: center;
}
.justify-between {
    justify-content: space-between;
}
.items-center {
    align-items: center;
}
.flex {
    display: flex;
}
.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}
.flex {
    display: flex;
}
.items-center {
    align-items: center;
}
.items-center {
    align-items: center;
}
.flex {
    display: flex;
}
.items-center {
    align-items: center;
}
.justify-center {
    justify-content: center;
}
.justify-center {
    justify-content: center;
}
.items-center {
    align-items: center;
}
.w-\[47\%\] {
    width: 47%;
}
.flex {
    display: flex;
}

.react-calendar { 
    width: 400px;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    border:2px ;
   }
   .react-calendar__month-view__weekdays__weekday abbr {
    font-weight: 600;
    font-size:14px;
    text-decoration: none;
  }
   .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
   }
   .react-calendar__navigation button:enabled:hover,
   .react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
   }
   .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
   }
   abbr[title] {
    text-decoration: none;
   }
   /* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
   } */
   .react-calendar__navigation__label {
    color: #676767;
    text-decoration: none;
    font-weight: 600;
    border:none;
  }
  .react-calendar__navigation__arrow {
    display: none; 
  }
  .selected-range {
    background-color:#0E6BA8;
    color: #ffffff;
  }
  .react-calendar__tile {
    border: none;
    background: none;
    font-weight: 300;
    font-size: 16px;
    color:rgba(37, 37, 37, 1);
   }
 
   .v2SelectCheckboxIcon{
    margin-left: -0px;
    height: 18px;
    width: 18px;
    cursor: pointer;
}
.v2SelectCheckboxIcon:after{
    content: " ";
    background-color: #9FFF9D;
    display: inline-block;
    visibility: visible;
}
.v2SelectCheckboxIcon:checked:after{
    height: 18px;
    width: 18px;
    color: white;
    border-radius: 2px;
    outline: 0.01em solid rgb(100,100,100);
    font-size: 16px;
    text-align: center;
    background-color: var(--ambient-color) !important;
    content: "\2714";
}

.heading-title{
    font-size: 1.2rem;
    background-color: #0E6BA8;
    color: #fff;
    text-align: center;
    font-family: "kanit", sans-serif;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
.tabs {
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid #ddd;
}

.tabs button {
    flex: 1; 
    padding: 10px 10px;
    border: none;
    background-color: #f1f1f1;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin: 0; 
}

.tabs button.active {
    background-color: #ddd;
    font-weight: bold;
    border-bottom: 2px solid #000;
}

.tabs button:hover {
    background-color: #ccc;
}

.tab-content {
    padding: 10px 20px 20px 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.table1 {
    width: 100%;
    border-collapse: collapse;
}

.table1 th, .table1 td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table1 th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.table1 tr:nth-child(even) {
    background-color: #f9f9f9;
}

.table1 tr:hover {
    background-color: #ddd;
}

.channel-title, .team-title {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 5px;
    cursor: pointer;
}

.team-title {
    font-size: 18px;
}

.channel-title {
    font-size: 16px;
}

.gain {
    color: green;
}

.dropData {
    color: red;
}
.total{
    text-align: right;
    margin-bottom: 10px;
}
.toggle-icon {
    margin-left: 10px;
}
