.permissionContainer{
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    margin-bottom: 10px;
}

.css-ars20s-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked{
    color:var(--container-shadow-color) !important;
}
.css-ars20s-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
    color:var(--container-shadow-color-hover-more-lighter) !important;
}
.css-ars20s-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
    background-color:var(--container-shadow-color-hover-lighter) !important;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: var(--container-shadow-color) !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
    color:rgb(220,220,220);
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color: var(--container-shadow-color) !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    /* border-color: var(--container-shadow-color) !important; */
}

.openedPermissionContainer{
    border: 1px solid rgb(220,220,220);
    border-top: 0px;
}

.closedPermissionContainer{
    height: 0px;
    transform: scale(0);
    position: absolute;
    border: 1px solid rgb(220,220,220);
}

.permissionContainerV3{
    width: 100%;
}