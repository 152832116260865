.exportCSVPopupContainer{
    height:100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    padding-top: 10vh;
    background-color: rgba(0, 0, 0, 0.104);
}

.exportCSVPopupWrapper{
    height: 70vh;
    width: 60vw;
    background-color: white;
    box-shadow: 0 0 5px 1px grey;
    display: flex;
    align-items: center;
}