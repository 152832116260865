:root{
    --container-bg-color: rgb(255, 255, 255);
    --container-shadow-color: rgb(159, 80, 0);
    --container-shadow-color-hover: rgb(228, 106, 0);
    --container-shadow-color-hover-lighter:rgb(255, 168, 68);
    --container-shadow-color-hover-more-lighter:rgb(251, 216, 177);
    --tab-font-color: rgb(117, 117, 117);
    --tab-font-color-hover: rgb(80, 80, 80);
    --table-headers-background: rgb(230, 230, 230);
    --font-color:white;
}

.demandOffersTopContainerV2{
    height:40px;
    display: flex;
    align-items: center;
    font-size: 12px;
    justify-content: space-between;
    border-bottom: 1px solid rgb(220,220,220);
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
}
.demandOffersSecondContainerV2{
    display:flex;
    flex-wrap: wrap;
    margin: 10px;
    padding-bottom: 15px;
    margin-bottom: 0px;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid rgb(220,220,220);
}

.selectFieldLabel{
    font-weight: 500;
    color:grey;
    font-size: 15px;
    padding-bottom: 2px;
}

.demandOffersThirdContainerV2{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
}

.demandOffersTableHeadContainer{
    display:flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    position: sticky;
    top: 0;
}

.demandOffersTableHead{
    font-size: 14px;
    padding: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 600;
    color:rgb(100,100,100);
    margin-right: 2px;
    min-height: 20px;
    background-color: rgb(245,245,245);  
    display: flex;
    align-items: center;
}

.demandOffersTableValueContainer{
    display:flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 12px;
    min-height: 15px;
    border-bottom: 1px solid rgb(240,240,240);
}
.demandOffersTableValueContainer:hover{
    background-color: var(--container-shadow-color-hover-more-lighter);
}

.demandOffersTableValue{
    font-size: 12px;
    padding: 5px;
    color:rgb(0, 0, 0);
    margin-right: 2px;
    min-height: 25px;
    display: flex;
    align-items: center;
    word-wrap: break-word;
    word-break: break-all;
}

.demandOffersEditMenu{
    position: absolute;
    min-height: 60px;
    background: white;
    min-width: 6%;
    margin-left: -3%;
    box-shadow: 0 0 5px 1px grey;
    z-index: 10000;
}
.demandOffersEditMenuItems{
    padding: 10px;
    padding-left: 10px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.demandOffersEditMenuItems:hover{
    background-color: var(--container-shadow-color-hover-lighter);
}

.demandOffersTilesViewContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.demandOffersTilesViewWrapper{
    min-height: 100px;
    width: 250px;
    margin: 15px;
    padding: 20px;
    padding-bottom: 0px;
    justify-content: flex-start;
    transition: all 0.2s linear;
}
.demandOffersTilesViewWrapper:hover{
    box-shadow: 0 0 5px 1px var(--container-shadow-color-hover) !important;
    transform: scale(1.02);
    transition: all 0.1s linear;
}
.demandOffersTilesViewLabel{
    font-size: 13px;
    font-weight: 500;
}
.demandOffersTilesViewValue{
    width: 50%;
    font-size: 12px;
    word-wrap: break-word;
    word-break:keep-all;
}

.demandSourceAddPlatformContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2%;
    color: rgb(0, 4, 42);
}

.demandSourceAddPlatformWrapper{
    height: 160px;
    width: 160px;
    margin: 20px;
    border: 1px solid rgb(240,240,240);
    box-shadow: 0 0 8px 1px rgb(168, 168, 168);
    transition: all 0.2s linear;
    position: relative;
}

.demandSourceAddPlatformWrapper:hover{
    box-shadow: 0 0 8px 1px rgb(88, 88, 88);
    transition: all 0.2s linear;
    transform: scale(1.02);
}
.demandSourceAddPlatformWrapperSelected{
    box-shadow: 0 0 8px 1px var(--container-shadow-color-hover) !important;
    transition: all 0.2s linear;
    transform: scale(1.02); 
}

.demandSourceDetailsContainer{
    padding:20px;
}
.demandSourceDataContainer{
    margin-top: 20px;
    border: 1px solid rgb(220,220,220);
    background: rgb(250,250,250);
}
.demandSourceDataContainerHeading{
    padding:10px;
    border-bottom:1px solid rgb(230,230,230);
    background:rgb(245,245,245);
    font-size:14px;
    font-weight:600;
}
.demandSourceDataContainerData{
    padding:5px;
    font-size:13px;
}
.demandSourceDataContainerDataLabel{
    font-weight: 500;
    padding:8px;
    width: 20%;
}

.demandOfferAutomationEditWrapper{
    margin-left:20px;
    margin-right:20px;
}

.demandOfferAutomationBottomBar{
    position: sticky;
    bottom: 0;
    height: 60;
}

.demandOfferFetchOfferPopup{
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    height: 100vh;
    width: 100vw;
    left: 0;
    z-index: 10000;
    position: fixed;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.136);
}

.demandOfferFetchOfferPopupContainer{
    width:80vw;
    height: 85vh;
    background-color: white;
}