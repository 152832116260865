.v2SelectComponent{
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
.v2SelectComponentHeightIncrease{
    position: absolute !important;
    height: 400px;
    top: 0;
    left: 0px;
    background-color: rebeccapurple;
}

.v2OpenSelectIcon{
    transform: scale(0.7);
    position: absolute;
    right: 0;
}
.v2SelectOptionContainer{
    position: absolute;
    width: calc(100% + 40px);
    background-color: white;
    box-shadow: 0 7px 20px 0 rgb(200,200,200);
    left: -20px;
    height: 250px;
    /* margin-top: 50%; */
    bottom: -250px;
    z-index: 20;
    overflow-y: auto;
    overflow-x: hidden;
}
.v2OptionContainer{
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
}
.v2OptionContainer:hover{
    background-color: var(--ambient-color-90-percent-lighter);
}
.v2OptionLabel{
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #252525;
    font-family: Kanit;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.v2SelectRadioIcon{
    margin-left: -5px;
}
.v2SelectCheckboxIcon{
    margin-left: -0px;
    height: 18px;
    width: 18px;
    cursor: pointer;
}
.v2SelectCheckboxIcon:after{
    content: " ";
    background-color: #9FFF9D;
    display: inline-block;
    visibility: visible;
}
.v2SelectCheckboxIcon:checked:after{
    height: 18px;
    width: 18px;
    color: white;
    border-radius: 2px;
    outline: 0.01em solid rgb(100,100,100);
    font-size: 16px;
    text-align: center;
    background-color: var(--ambient-color) !important;
    content: "\2714";
}