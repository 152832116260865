.backendServiceContainer{
    margin-left: 5rem;
    margin-right: 5rem;
}
.backendServiceFlex{
    margin-top: 10px;
    display: flex;
    margin-bottom: 10px;
    justify-content: start;
    align-items: center;
}

.backendServiceItem{
    margin: 10px;
    margin-top: 5px;
    padding: 15px;
    padding-top: 7px;
    padding-bottom: 10px;
    border-radius: 15px;
    font-size: 14px;
    border: 2px solid rgb(220,220,220);
    cursor: pointer;
}

.backendServiceItemActive{
    border: 2px solid var(--ambient-color);
    background-color: white;
}


.headerLabel {
    color: #252525;
    font-family: "Oswald", sans-serif;
    font-size: 2rem;
  }