:root{
    --container-bg-color: rgb(255, 255, 255);
    --container-shadow-color: rgb(159, 80, 0);
    --container-shadow-color-hover: rgb(228, 106, 0);
    --container-shadow-color-hover-lighter:rgb(255, 168, 68);
    --container-shadow-color-hover-more-lighter:rgb(251, 216, 177);
    --tab-font-color: rgb(117, 117, 117);
    --tab-font-color-hover: rgb(80, 80, 80);
    --table-headers-background: rgb(230, 230, 230);
    --font-color:white;
}

.offerTabsContainerV3{
    display: flex;
    margin-bottom: 10px;
    height: 62px;
    overflow: auto;
    /* position: sticky;
    top: 0;
    z-index: 1000; */
}
.offerTabV3{
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 2.5px;
    margin-right: 2.5px;
    font-size: 15px;
    padding-left: 15px;
    padding-right: 15px;
    border:1px solid var(--container-shadow-color-hover);
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: white;
    transition: all 0.3s ease-out;
}
.offerTabV3Selected{
    background-color: var(--container-shadow-color-hover);
    color: white;
    height: 55px;
    transform: scale(1.03);
    transition: all 0.3s ease-out;
}