.footerShow{
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999999992;
    font-size: 12px;
    height: 20px;
    background-color: rgb(230, 230, 230);
    display: flex;
    align-items: center;
    padding-left: 1%;
    transition: all 1s ease-in;
}

.footerLink{
    color : rgb(226, 113, 37);
    text-decoration: none;
}