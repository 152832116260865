.sidebarContainer{
    background-color: rgb(245, 245, 245);
    height: 97.5vh;
    margin-top: 60px;
    position: fixed;
    overflow-y: auto;
    z-index: 1001 !important;
    
}
.sidebarContainer:hover{
    width: 15vw !important;
}

.tabLink{
    display:flex;
    text-decoration:none;
    color: rgb(80, 80, 80);
}

.tabLink:hover{
    color: rgb(0, 0, 0);
}
.tabLink:active{
    color: rgb(0, 0, 0);
}

.tabIcon{
    padding:20px;
    padding-top:10px;
    padding-right:0px;
    transition: all 0.3s ease-in-out;
}


.tabName{
    padding:22px;
    padding-top:10px;
    padding-left: 10px;
    font-size: 15px;
    transition: all 0.3s ease-in-out;
}

.tabNameClosed{
    opacity:0;
    padding:20px;
    padding-top:10px;
    padding-left: 0px;
    transition: all 0.3s ease-in-out;
}

.openTabList{
    position: absolute;
    right: 10%;
    margin-top: 3%;
    opacity: 1;
    transition: all 1.5s ease-in-out;

}

.openTabListClosed{
    position: absolute;
    right: 10%;
    margin-top: 7%;
    opacity: 0;
}


.sideBarDropDownLink{
    display: flex;
    color: rgb(80, 80, 80);
    justify-content: flex-end;
    text-decoration: none;
    margin-top: 0px;
    margin-right: 30%;
    margin-bottom: 15px;
    font-size: 15px;
    position: sticky;
    /* transform: translateY(20px); */
    transition: all 0.5s ease-in-out; 
}

.sideBarDropDownLink:hover{
    color: rgb(0, 0, 0);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.sideBarDropDownLinkClosed{
    display: flex;
    justify-content: flex-end;
    color: rgb(67,67,67);
    text-decoration: none;
    margin-top: -46px;
    margin-right: 15%;
    margin-bottom: 30px;
    position: absolute;
    font-size: 1px;
    opacity: 0;
    left:15vw;
    transform: translateY(20px);
    visibility: hidden;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}

.line{
    background-color: orange;
    width: 2px;
    margin-left: 84%;
    position: absolute;
    transform-origin: top;
    transition: all 0.5s ease-in-out;
}

.lineclosed{
    background-color: orange;
    width: 2px;
    margin-left: 84%;
    position: absolute;
    height: 0px;
    transform-origin: top;
    transition: all 0s ease-in-out;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgb(163, 163, 163); 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 0);
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(58, 58, 58); 
  }