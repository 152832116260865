
.advertiserPageV2{
    /* display: flex; */
    min-height: 82vh;
    background: var(--container-bg-color);
    margin: 2%;
    width: auto;
    padding: 10px;
    /* padding: 1%;
    padding-left: ;
    padding-bottom: 0%;
    padding-top: 0%; */
    margin-bottom: 3%;
    border-radius: 2px;
    box-shadow: 0 0 5px 1px var(--container-shadow-color);
    transition: all 0.5s ease-in-out;
}

.advertiserPageV2:hover{
    box-shadow: 0 0 10px 1px var(--container-shadow-color-hover);
    transition: all 0.5s ease-in-out;
}
.containerFirstRow{
    padding-left: 15px;
    padding-right: 15px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
}
.containerFirstRowColTwo{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.containerSecondRow{
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    transform: scale(0.9) translate(-5%);
    margin-bottom: 30px;
}
.containerThirdRow{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    transform: scale(0.8);
    transform-origin: right;
    margin-top: -30px; 
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 30px; 
}

.threeDotMenuOption{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 15px;
    text-decoration: none;
    color: black;
    padding-top: 10px;
    padding-bottom: 10px;
}
.threeDotMenuOption:hover{
    background-color: var(--container-shadow-color-hover-more-lighter);
}

.advertiserPageDataContainer{
    display: flex;
    flex-wrap: wrap;
}
.advertiserPageDataWrapper{
    min-height: 200px;
    margin: 20px;
    border: 1px solid rgb(220,220,220);
    background-color: rgb(252, 252, 252);
}
.advertiserPageDataHeader{
    display: flex;
    padding: 10px;
    border-bottom: 1px solid rgb(220,220,220);
    background-color: rgb(242, 242, 242);
    font-size: 16px;
    font-weight: 600;
}
.advertiserPageGeneralDataContainer{
    padding: 20px;
}
.advertiserPageGeneralDataWrapper{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
}
.addEditAdvertiserPageLabel{
    font-size: 13px;
    text-align: right;
    width: 20%;
}
.addEditAdvertiserPageTextField{
    height: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 65%;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 13px;
    color: rgb(50,50,50);
    padding-left: 10px;
    outline: none;
    border-radius: 4px;
    border: 1px solid rgb(200,200,200);
}


.addEditAdvertiserPageTextField:hover{
    border: 1px solid transparent;
    box-shadow: 0 0 1px 1px var(--container-shadow-color-hover-lighter);
}
.addEditAdvertiserPageTextField:focus{
    border: 1px solid transparent;
    box-shadow: 0 0 3px 1px var(--container-shadow-color-hover);
}

.invalidAddEditAdvertiserPageTextField{
    border: 1px solid red;
    box-shadow: 0 0 1px 1px red;
}
.invalidAddEditAdvertiserPageTextField:hover{
    border: 1px solid transparent;
    box-shadow: 0 0 1px 1px red;
}
.invalidAddEditAdvertiserPageTextField:focus{
    border: 1px solid transparent;
    box-shadow: 0 0 1px 1px red;
}

.addEditAdvertiserPageTextField:disabled{
    border: 1px solid rgb(200,200,200);
    color:gray;
    box-shadow: 0 0 2px 1px transparent;
}
.addEditAdvertiserPageSelectField{
    margin-left: 5%;
}

.advertiserPageButtonPallete{
    position: sticky;
    bottom: 20px;
    margin-top: 20px;
    min-height: 50px;
    border-top: 1px solid rgb(220,220,220);
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
}

.gridComponentAdvertiser{
    width: 280px;
    min-height: 300px;
    box-shadow: 0 0 3px 1px green;
    margin: 10px;   
    transition: all 0.2s ease-in-out;
}
.gridComponentAdvertiser:hover{
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
}
.advertiserPageV2ChangeStatus{
    padding: 6px;
    font-weight: 600;
    cursor: pointer;
}
.advertiserPageV2ChangeStatus:hover{
    box-shadow: 0 0 10px 2px var(--container-shadow-color-hover-lighter);
    background-color: var(--container-shadow-color-hover-lighter);
}

.advertiserPageV2ChangeStatus:focus{
    box-shadow: 0 0 10px 2px var(--container-shadow-color-hover-lighter);
    background-color: var(--container-shadow-color-hover-lighter);
}
.advertiserPageV2TabsContainer{
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: white;
    z-index: 10;
    padding-top: 5px;
    padding-bottom: 5px;

}
.advertiserPageV2Tabs{
    padding: 10px;
    margin-top: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
}

.addEditAdvertiserPageCustom{
    height: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 65%;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 13px;
    color: rgb(50,50,50);
    padding-left: 10px;
    outline: none;
    border-radius: 4px;
}


@media screen and (max-width: 900px) {
    .advertiserPageDataWrapperMobile{
        min-width: 100% !important;
    }
    .advertiserPageDataContainer{
        padding: 30px;
    }
}