:root{
    --container-bg-color: rgb(255, 255, 255);
    --container-shadow-color: rgb(159, 80, 0);
    --container-shadow-color-hover: rgb(228, 106, 0);
    --container-shadow-color-hover-lighter:rgb(255, 168, 68);
    --container-shadow-color-hover-more-lighter:rgb(251, 216, 177);
    --tab-font-color: rgb(117, 117, 117);
    --tab-font-color-hover: rgb(80, 80, 80);
    --table-headers-background: rgb(230, 230, 230);
    --font-color:white;
}

.mainContainerV2{
    /* display: flex; */
    min-height: 82vh;
    background: var(--container-bg-color);
    margin: 2%;
    width: auto;
    /* padding: 1%;
    padding-left: ;
    padding-bottom: 0%;
    padding-top: 0%; */
    margin-bottom: 3%;
    border-radius: 2px;
    box-shadow: 0 0 5px 1px var(--container-shadow-color);
    transition: all 0.5s ease-in-out;
}

.mainContainerV2:hover{
    box-shadow: 0 0 10px 1px var(--container-shadow-color-hover);
    transition: all 0.5s ease-in-out;
}
.mainContainerTopbar{
    width: 96%;
    margin-left: 1%;
    margin-right: 1%;
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 1%;
    padding-right: 1%;
    border-bottom: 1px solid rgb(174, 174, 174);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: linear-gradient(rgba(255, 255, 255, 0.854),rgba(255, 255, 255, 0.182));
}

.mainContainerTopbarTab{
    display: flex;
    /* font-weight: 500; */
    width: 20%;
    font-size: 18px;
}

.mainContainerTab{
    padding-left: 1%;
    padding-right: 1%;
    color:var(--tab-font-color);
    cursor: default;
    user-select: none;
    font-size: 13px;
}
.mainContainerTab:hover{
    transform: scale(1.03);
    color:var(--tab-font-color-hover);
}
.mainContainerTabActive{
    padding-left: 1%;
    padding-right: 1%;
    cursor: default;
    user-select: none;
    font-size: 14px;
    /* transform: scale(1.02); */
    color:var(--container-shadow-color-hover);
}

.mainContainerTopbarIcons{
    display: flex;
    justify-content: flex-end;
}

.mainContainerIcon{
    color:var(--tab-font-color);
    transform: scale(0.8);
}


.searchAndFilterBar{
    display: flex;
    padding: 10px;
    padding-top: 0px;
    border-radius: 5px;
    padding-right: 5%;
    align-items: center;
    /* min-height:70px; */
    flex-wrap: wrap;
    margin-bottom: 1%;
}
.searchTextfieldContainer{
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 2%;
    width: 350px;
    border: 1px solid rgb(200, 200, 200);
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 0 2px 1px rgb(200, 200, 200);
}

.searchTextfieldContainerActive{
    border: 1px solid transparent;
    background-color: white;
    outline: 1px solid var(--container-shadow-color-hover);
    box-shadow: 0 0 4px 1px var(--container-shadow-color-hover);
}

.searchTextfieldInput{
    width: 50%;
    outline:none;
    border: none;
    font-size: 14px;
}
.searchTextfieldInput:focus{
    outline:none;
}


.searchBarIcon{
    color:var(--tab-font-color);
    transform: scale(0.7);
}
.searchBarIconActive{
    color:var(--container-shadow-color-hover);
}

.searchBarCloseIcon{
    color:rgb(150,150,150);
    transform: scale(0.9) translateY(10%);
    cursor: pointer;
}

.searchBarCloseIcon:hover{
    color:rgb(100,100,100);
}


.filterButton{
    margin-top: 18px;
    margin-left: 3%;
    /* height: 34px; */
    width: 90px;
    border: 1px solid grey;
    padding-left: 5px;
    padding-right: 0px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
    font-size: 12px;
    color: rgb(80,80,80);
    transition: all 0.1s ease-in;
}

.filterButton:hover{
    transform: scale(1.02);
    transition: all 0.1s ease-in;
    border: 1px solid var(--container-shadow-color-hover);
    box-shadow: 0 0 2px 0.01px var(--container-shadow-color-hover);
    color: var(--container-shadow-color-hover)
}

.filterButton:active{
    transition: all 0.1s ease-in;
    transform: scale(1.06);
    border: 1px solid var(--container-shadow-color-hover);
    box-shadow: 0 0 2px 0.01px var(--container-shadow-color-hover);
    color: var(--container-shadow-color-hover)
}

.filterButtonIcon{
    transform: scale(0.9) translateY(10%) translateX(-10%);
    cursor: pointer;
}
.filterItemHolder{
    margin-left: 2%;
    margin-top: 2%;
}

.filterTitle{
    font-size: 12px;   
    margin-left: 2%; 
    font-weight: 500;
}

.filterMenuItems{
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;
    cursor: pointer;
    user-select: none;
}

.filterMenuItems:hover{
    background-color: var(--container-shadow-color-hover);
}

.filterMenuItemsDisabled{
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;
    cursor: not-allowed;
    user-select: none;
}

.filterMenuItems:hover{
}

.filterMenuSearch{
    display: flex;
    justify-content: center;
    align-items: center;
}

.filterMenuButton{
    /* padding:2%; */
    user-select: none;
    cursor: pointer;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    background-color: var(--container-shadow-color-hover);
}

.filterSelected{
    margin-top: 5px;
    margin-right: 1%;
    margin-left: 1%;
    min-width: 150px;
    overflow-y: auto;
    border: 1px solid rgb(200, 200, 200);
    min-height: 30px;
    max-height: 200px;
    height: auto;
    flex-wrap: wrap;
    margin-left: 2%;
    display: flex;
    align-items: center;
    font-size: 12px;
    color:rgb(150,150,150);
    border-radius: 5px;
    cursor: default;
    user-select: none;
    box-shadow: 0 0 2px 1px rgb(200, 200, 200);
}

.filterSelectedActive{
    border: 1px solid transparent;
    box-shadow: 0 0 5px 1px var(--container-shadow-color-hover);
}

.selectedFilterItems{
    margin: 2%;
    margin-bottom: 2%;
    color:black;
    border-radius: 3px;
    width: auto;
    /* padding: 5px; */
    padding-left: 5%;
    padding-right: 2%;
    display: flex;
    align-items: center;
}

.customSelect{
    /* min-width: 5%; */
    /* width: max-content; */
    min-height: 30px;
    outline: none;
    border: none;
    display: flex;
    justify-content: flex-end;
    color: var(--tab-font-color);

}


.tableRowV2{
    display: flex;
    /* padding:10px; */
}
.tableRowV2:hover{
    background-color: rgb(240, 240, 240);
    /* transform: scaleZ(1); */
}

.tableHeaderV2{
    position: fixed;
    left: 0;
    z-index: 500000;
    display: flex;
    align-items: center;
}
.fixedHeader{
    padding:8px;
    padding-bottom: 0px;
    padding-top: 0px;
    position:sticky;
    z-index: 1;
    top:500px;
    background-color: rgb(255, 178, 178);
    border: 1px solid white;
}

.editButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color:var(--container-shadow-color-hover)
}

.selectedOfferTopBar{
    display:flex;
    justify-content:space-between;
    background-color: var(--container-shadow-color-hover-lighter);
    align-items:center;
    height: 50px;
    position:sticky;
    top:0;
    z-index:13;
    width:calc(100%);
    color: var(--font-color);
    /* border-top-left-radius:10px;
    border-top-right-radius:10px; */
}

.actionBarButtonsv2{
    display: flex;
    height:4vh;
    padding-right: 2%;
    padding-left: 2%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 1%;
    font-size: 14px;
    cursor:pointer;
    transition: all 0.3ms ease-in-out;
    user-select: none;
}

.actionBarButtonsv2:hover{
    background-color: var(--container-shadow-color-hover);
}

.actionBarButtonsv2:active{
    background-color: var(--container-shadow-color);
    transform: scale(1.06);
    transition: all 0.3ms ease-in-out;
}

.actionBarMenuItemsv2{
    padding:10%;
    font-size: 12px;
    font-weight: 500;
    user-select: none;
    cursor: default;
    display: flex;
    align-items: center;
}

.actionBarMenuItemsv2:hover{
    background-color: var(--container-shadow-color-hover);
}

section {
    margin-left: 2%;
    margin-right: 2%;
    width: 96%;
    position: relative;
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(22, auto);
    /* grid-template-rows: repeat(6, 50px) */
  }
  
.headerResizable{
    background: white;
    padding-left:10px;
    padding-right:10px;
    /* padding-bottom: 10px; */
    border-right: 1px solid rgb(205, 205, 205);
}


header {
    background: white;
    position: sticky;
    top: 0;
    z-index: 11;
    border-bottom: 1px solid rgb(205, 205, 205);
}
  
.rh {
    position: sticky;
    font-size: 12px;
    background-color: white;
    padding-top: 5px;
    padding-bottom: 5px;
}
  
header:first-child {
    left: 0;
    z-index: 11;
}

.noscrollbar{
    scrollbar-width: none;
}

.noscrollbar::-webkit-scrollbar{
    display: none;
    background-color: red;
}

.noscrollbar::-webkit-scrollbar{
    display: none;
    background-color: red;
}
  
.offerV2Row:hover{
    background-color: var(--container-shadow-color-hover-lighter);
}


/* VIEW OFFER PAGE */
.offerViewV2Container{
    border:1px solid rgb(220,220,220);
    margin:20px;
    margin-top: 0px;
}

.titleOfferViewV2{
    background:rgb(250,250,250);
    padding:10px;
    border-bottom:1px solid rgb(220,220,220);
    font-size:13px;
    font-weight: 500;
    color:rgb(100,100,100);
    display:flex;
}
.labelValueOfferView{
    display:flex;
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 15px;
    /* align-items: center; */
}

.labelOfferView{
    width: 20%;
    text-align: right;
    font-weight: 500;
}
.valueOfferView{
    width: 78%;
    word-wrap: break-word;
    word-break: break-all;
    padding-right: 5%;
}
.offerCategoriesViewV2{
    margin-right: 5px;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    border: 2px solid var(--container-shadow-color-hover);
    background-color: var(--container-shadow-color-hover-more-lighter);
}

.stopRedirectPopup{
    width:30vw;
    min-height:20vh;
    background:white;
    box-shadow: 0 0 10px 1px var(--container-shadow-color-hover);
}
.stopRedirectPopupHeader{
    height: 35px;
    border-bottom: 1px solid var(--container-shadow-color-hover);
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
}
.stopRedirectPopupBody{
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
}


/* NEW OFFER PAGE */

.OfferTabActive{
    color:var(--container-shadow-color-hover);
    font-size: 10px;
    font-weight: 500;
}
.OfferTabInactive{
    color:rgb(66, 66, 66);
    font-size: 10px;
    font-weight: 500;
}

.offerEditContainerV2{
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
}
.offerEditLabelValueV2{
    display: flex;
    align-items: center;
}
.offerEditLabelV2{
    font-weight: 500;
    font-size: 12px;
    width:20%;
    display: flex;
    color:rgb(84, 84, 84);
}

.ck-editor__main{
    max-height: 200px;
    overflow-y: auto;
    font-size: 12px;
}

.payoutContainerV2{
    width:96%;
    background-color: rgb(249, 249, 249);
    border: 1px solid rgb(230,230,230);
    padding: 1%;
    padding-left: 2%;
    padding-right: 2%;
    font-size: 12px;
    margin-bottom: 20px;
}

.addButtonV2{
    margin-top: 20px;
    margin-left: 20px;
    font-size: 18px;
    width: 20px;
    border-radius: 3px;
    text-align: center;
    color: grey;
    outline: 0.001em solid rgb(200,200,200);
    box-shadow: 0 0 2px 1px rgb(200,200,200);
    cursor: pointer;
}
.addButtonV2:hover{
    color: var(--container-shadow-color-hover-lighter);
    outline: 0.001em solid var(--container-shadow-color-hover-lighter);
    box-shadow: 0 0 2px 1px  var(--container-shadow-color-hover-lighter);
    transition: all 0.1s linear;
}
.payoutIconsV2{
    font-size: 13px;
    margin-left: 10px;
    color:red;
}