.userlogsDetailPopupContainer{
    position: fixed;
    height: 100vh;
    width: 100vw;
    top:0;
    left: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.026);
    display: flex;
    justify-content: center;
    backdrop-filter: blur(1px);
}

.userlogsDetailPopupWrapper{
    min-height: 50vh;
    max-height: 80vh;
    max-width: 60vw;
    position: absolute;
    top:10%;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    border: 1px solid rgb(200,200,200);
    padding-right: 25px;
}

.userlogTableHeaders{
    font-size: 16px;
    font-weight: 500;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: rgb(240,240,240);

}
.userlogTableValueRow:hover{
    background-color: var(--container-shadow-color-hover);
    color:white;
}
.userlogTableValues{
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
}

.updatedValuesPreviousValues {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}