.billingTableHeadData{
    border-left: 1px solid rgb(220,220,220);
    border-top: 1px solid rgb(220,220,220);
    border-bottom: 1px solid rgb(220,220,220);
    padding: 10px;
    font-size: 14px;
    color: rgb(50,50,50);
}
.billingTableHeadData:last-child{
    border-right: 1px solid rgb(220,220,220);
}

.billingTableValueData{
    border-left: 1px solid rgb(220,220,220);
    border-top: 1px solid rgb(220,220,220);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 13px;
    color: rgb(50,50,50);
}
.billingTableValueData:last-child{
    border-right: 1px solid rgb(220,220,220);
}
.billingTableAddRow{
    background-color: rgb(72, 197, 0);
    color: white;
    font-size: 13px;
    font-weight: 500;
    width: max-content;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}
.invoiceFlex{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
}
.invoiceLabel{
    min-width: 200px;
    font-weight: 500;
}
.invoiceValue{

}