.PopupColumncontainer{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(165, 165, 165, 0.137);
    height: 100vh;
    width: 100vw;
    z-index: 10000;
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper{
    background-color: rgb(255, 255, 255);
    width: 60%;
    height: 90vh;
    box-shadow: 1px 1px 10px 1px rgb(92, 92, 92);
    margin-bottom: 3%;
}

.closeIcon {
    content: 'x';
    cursor: pointer;
    color:rgb(0, 0, 0);
    background: #ededed00;
    width: 25px;
    height: 25px;
    /* border-radius: 50%; */
    line-height: 20px;
    text-align: center;
    font-size: 20px;
    z-index: 100;
  }