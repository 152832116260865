
:root{
    --container-shadow-color-hover: rgb(228, 106, 0);
    --container-shadow-color-hover-lighter:rgb(255, 168, 68);
}

.containerExport{
    display: flex;
    flex-wrap: wrap;
    margin:2%;
    width: 96%;
    border-radius: 1px;
    box-shadow: 0 0 10px 2px var(--container-shadow-color-hover-lighter);
    min-height: 79vh;
    transition: all 0.5s ease-in-out;
    background-color: rgb(255, 255, 255);
}

.containerExport:hover{
    /* transform: scale(1.01); */
    transition: all 0.5s ease-in-out;
    box-shadow: 0 0 10px 2px var(--container-shadow-color-hover);
}

.tableHead{
    padding: 8px;
    font-size: 14px;
    background-color: rgb(218, 218, 218);
}

.tableData{
    padding: 8px;
    font-size: 13px;
    background-color: rgb(255, 255, 255);
}